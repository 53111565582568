import {
  Menu,
  Tooltip,
  Typography,
  Modal,
  Carousel,
  Col,
  Row,
  Badge,
  Avatar,
  Card,
  Button,
  Select,
  Tabs,
  Affix,
  message,
  Drawer,
  Layout,

} from "antd";
import styled from 'styled-components';
import {
  HomeOutlined,
  ShoppingOutlined,
  PhoneFilled,
  EnvironmentFilled,
  PlusOutlined,
  PushpinOutlined,
  LeftOutlined,
  RightOutlined,
  MinusOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Link, animateScroll as scroll } from "react-scroll";
import React, { useState, useEffect, useLayoutEffect } from "react";
import ygenLogo from '../../Assets/images/ygen-withoutBack.png'
import clogoimage from "../../Assets/images/ygen-withoutBack.png";
import categoryoil from "../../Assets/images/oil.jpg";
import sproductimage from "../../Assets/images/product01.jpg";
import categoryImage from '../../Assets/images/categoryImage.png'
import playstore from "../../Assets/images/playstore.svg";
import appstore from "../../Assets/images/appstore.svg";
import ScrollSpy from "react-ui-scrollspy";
import Scrollspy from 'react-scrollspy'

import axios from "axios";
import {
  AREA_BRANCH_ID,
  BRANCH_DETAIL,
  CART_LIST,
  COMPANY_ID,
} from "../../Constants/ThemeConstants";
import { decryptData, encryptData } from "../../Funtions/EncryptDecrypt";
import ProductModal from "../../Component/ProductModal";
import HalfnHalfModal from "../../Component/HalfnHalfModal";
import { useSelector, useDispatch } from "react-redux";
import CartDrawer from "../../Component/CartDrawer.jsx";
import {
  CHANGE_ORDER_MODE,
  SET_BRANCH_DETAIL,
  SET_DEAL_LIST,
  SET_GST_DETAIL,
  SET_PRODUCT_LISTING,
  SET_PRODUCT_REQUEST,
  SET_SELECTED_PRODUCT_DETAIL,
  SET_THEME_COLOR,
  TOGGLE_CART_DRAWER,
  TOGGLE_DEAL_MODAL,
  TOGGLE_LOADED,
  TOGGLE_PRODUCT_MODAL,
  SET_PRODUCT_CART,
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY
} from "../../Redux/ReduxConstants";
import DealModal from "../../Component/DealModal";
import SkeletonLoader from "../../Component/SkeletonLoader";
import { useRef } from "react";
import { getRandomNumber, isNullValue } from "../../Funtions/GeneralFunction";
import { type } from "@testing-library/user-event/dist/type";




const { Title } = Typography;
const { Meta } = Card;
const { Option } = Select;
const { Header } = Layout;

let sliderimages = [];

const Main = () => {

  const {
    cartDrawer,
    cartList,
    productListing,
    backgroundColor,
    themeSecondaryColor,
    themeColor,
    button,
    banner,
    logo,
    topBarText,
    topBarBgColor,
    topBarForeColor,
    categoryBarBgColor,
    categoryBarForeColor,
    categoryHoverColor,
    categoryActiveColor,
    productBgColor,
    productNameForeColor,
    productDescForeColor,
    productHoverColor,
    productPriceBgColor,
    productPriceForeColor,
    productAddBtnBgColor,
    footerBgColor,
    footerForeColor,
    viewCartBgColor,
    viewCartForeColor,
    productPopupBgColor,
    productPopupHeaderBgColor,
    productPopupHeaderForeColor,
    productPopupDescForeColor,
    productPopupPriceForeColor,
    productPopupAddToCartForeColor,
    productPopupAddToCartBgColor,
    productPopupPlusMinusBgColor,
    productPopupQtyForeColor,
    dealPopupOptionNameForeColor,
    dealPopupProductNameForeColor,
    totalAmount,
    branchDetail
  } = useSelector((state) => state.ProductCartReducer);

  const dispatch = useDispatch();

  const { TabPane } = Tabs;
  const { loaded } = useSelector((state) => state.ProductCartReducer);
  const [mainLoader, setMainLoader] = useState(true);
  const [tabIndex, setTabIndex] = useState(localStorage.getItem("index"));
  const [counter, setCounter] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [deliveryPickupData, setDeliveryPickupData] = useState({});
  const [sliderimages, setSliderimages] = useState([]);

  // const [logo, setLogo] = useState("");

  const [modalData, setModalData] = useState({
    CityId: null,
    AreaId: null,
    BranchId: null,
  });
  const [initialAreaId, setInitialAreaId] = useState(null)
  const [initialBranchId, setInitialBranchId] = useState(null)
  const [initialCityId, setInitialCityId] = useState()

  const [selectedProductDetail, setSelectedProductDetail] = useState({
    product: null,
    productDetailList: [],
    sizeList: [],
    flavourList: [],
    selectedSize: null,
    selectedFlavour: null,
  });

  const showModal1 = () => {
    setIsModalVisible(true);
  };

  const showModal2 = () => {
    setIsModal2Visible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = () => { };

  const getDiscountPercent = (productDetailId) => {
    let discount = productListing?.Table13?.filter(
      (discountMap) => discountMap.ProductDetailId === productDetailId
    )
    if (discount?.length > 0) {
      let discountPercent = discount[0].DiscountPercent;
      return discountPercent;
    }
    return;
  };

  const checkDiscountPrice = (productDetailId, price) => {
    let discount = productListing?.Table13?.filter(
      (discountMap) => discountMap.ProductDetailId === productDetailId
    )
    if (discount?.length > 0) {
      let discountPrice = (price * discount[0].DiscountPercent) / 100;
      return [parseFloat(price - discountPrice).toFixed(2), true];
    }
    return [parseFloat(price).toFixed(2), false];
  };



  // const onPress = (e, i) => {
  //   setSelectedOption(productListing?.Table1[i].CategoryId);
  //   const target = window.document.getElementById(e.key);
  //   if (target) {
  //     target.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  // function leftScroll() {
  //   const left = document.querySelector(".outerDiv");
  //   left.scrollBy(-200, 0);
  // }
  // function rightScroll() {
  //   const right = document.querySelector(".outerDiv");
  //   right.scrollBy(200, 0);
  // }



  useEffect(() => {

    if (loaded === false) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}GetWebOrderAddress`, {
          OperationId: 1,
          CompanyId: parseInt(process.env.REACT_APP_COMPANYID), // decryptPayload(params.id).CompanyId,
        })
        .then((res) => {
          localStorage.setItem(COMPANY_ID, process.env.REACT_APP_COMPANYID); //params.id);
          let data = JSON.parse(localStorage.getItem(AREA_BRANCH_ID));
          let cartItems = decryptData(CART_LIST);
          if (data != null) {
            setModalData(data);
          } else {
            localStorage.setItem(
              AREA_BRANCH_ID,
              JSON.stringify(res.data.DataSet)
            );
          }
          if (cartItems == "" || cartItems == undefined) {
            setIsModalVisible(true);
            // setInitialAreaId(res?.data?.DataSet?.Table1[0]?.AreaId)
            // setInitialBranchId(res?.data?.DataSet?.Table2[0]?.BranchId)
            // console.log('checking initial area', res?.data?.DataSet?.Table1[0]?.AreaId, res?.data?.DataSet?.Table2[0]?.BranchId)
          } else {

            getProducts();
          }
          setDeliveryPickupData({ ...res.data.DataSet });
          // dispatch({ type: TOGGLE_LOADED }); if loaded is true than it will get data and our dropdown will be empty
        });
    } else {
      // setIsModalVisible(false);` modal will not be shown if setIsModalVisible is false
      setMainLoader(false);
      getBannerAndTheme();
    }
    getProducts()
  }, []);

  useLayoutEffect(() => {
    getBannerAndTheme();

  }, []);




  const wait = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const getProducts = async () => {
    // Wait for 5 seconds
    // await wait(5000);

    console.log('I am called', initialAreaId, initialBranchId);


    const areaIdToUse = modalData.AreaId !== null ? modalData.AreaId : initialAreaId;
    const branchIdToUse = modalData.BranchId !== null ? modalData.BranchId : initialBranchId;

    console.log(isNullValue(areaIdToUse) || (tabIndex !== "1" && isNullValue(branchIdToUse)));

    if (isNullValue(areaIdToUse) || (tabIndex !== "1" && isNullValue(branchIdToUse))) {
      setIsLoading(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}GetWebOrderAddress`, {
          OperationId: 2,
          CompanyId: process.env.REACT_APP_COMPANYID, // decryptPayload(params.id).CompanyId,
          IsMobile: false,
          IsWeb: true,
          AreaId: tabIndex === "2" ? null : areaIdToUse,
          BranchId: tabIndex === "1" ? null : branchIdToUse,
        })
        .then((res) => {
          setIsLoading(false);

          if (res.data.DataSet.Table.length === 0) {
            message.error("No Product Found");
            return;
          }
          if (res.data.DataSet?.Table[0]?.HasError === 1) {
            message.error(res.data.DataSet.Table[0]?.Error_Message);
            return;
          }
          setMainLoader(false);
          let data = JSON.parse(localStorage.getItem(AREA_BRANCH_ID));
          encryptData(res.data.DataSet.Table9[0], BRANCH_DETAIL);
          tabIndex === "1"
            ? dispatch({
              type: CHANGE_ORDER_MODE,
              payload: 111,
            })
            : dispatch({
              type: CHANGE_ORDER_MODE,
              payload: 110,
            });
          if (JSON.stringify(data) === JSON.stringify(modalData)) {
            let cart = decryptData(CART_LIST);
            if (cart != "") {
              dispatch({
                type: SET_PRODUCT_REQUEST,
                payload: {
                  branchDetail: res.data.DataSet.Table9[0],
                  cartList: cart,
                },
              });
            }
          } else {
            localStorage.setItem(CART_LIST, JSON.stringify([]));
            localStorage.setItem(BRANCH_DETAIL, {});
          }

          localStorage.setItem(
            AREA_BRANCH_ID,
            JSON.stringify({
              AreaId: modalData.AreaId || null,
              BranchId: modalData.BranchId || null,
              CityId: modalData.CityId || null,
            })
          );

          localStorage.setItem(
            BRANCH_DETAIL,
            JSON.stringify(res.data.DataSet.Table11[0])
          );

          dispatch({
            type: SET_PRODUCT_LISTING,
            payload: { ...deliveryPickupData, ...res.data.DataSet },
          });
          dispatch({
            type: SET_BRANCH_DETAIL,
            payload: { ...deliveryPickupData, ...res.data.DataSet.Table11[0] },
          });
          dispatch({
            type: SET_GST_DETAIL,
            payload: res.data.DataSet.Table10,
          });

          if (modalData.AreaId || modalData.CityId || modalData.BranchId) {
            setIsModalVisible(false);
          }
        });
    }
  };



  const getBannerAndTheme = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}GetWebOrderingSetting`, {
        OperationId: 1,
        CompanyId: parseInt(process.env.REACT_APP_COMPANYID),
        SettingId: null,
        Value: "",
        UserId: null,
        UserIP: "",
        SetupDetailId: null,
      })
      .then((response) => {
        if (response.error === true) {
          message.error(response.errorMessage);
          return;
        }
        if (response.data.response === false) {
          message.error(response.DataSet.Table.errorMessage);
          return;
        }
        const {
          data: {
            DataSet: { Table1 },
          },
        } = response;
        console.log('samad is looking for logo value in table1', Table1)
        const getValue = (key) => Table1?.filter((x) => x.SetupDetailName === key)[0]?.SettingValue;
        // debugger;
        setSliderimages(
          Table1?.filter((x) => x.SetupDetailName === "Upload Banner").map(
            (x) => x.SettingValue
          )
        );
        // setLogo(
        //   Table1?.filter((x) => x.SetupDetailName === "Logo")[0]?.SettingValue
        // );

        dispatch({
          type: SET_THEME_COLOR,
          payload: {
            themeColor: getValue("themeColor"),
            themeSecondaryColor: getValue("themeSecondaryColor"),
            backgroundColor: getValue("secondaryColor"),
            button: getValue("Button"),
            banner: getValue("Upload Banner"),
            logo: getValue("Upload Logo"),
            topBarText: getValue("Top Bar Text"),
            topBarBgColor: getValue("Top Bar Bg Color"),
            topBarForeColor: getValue("Top Bar Fore Color"),
            categoryBarBgColor: getValue("Category Bar Bg Color"),
            categoryBarForeColor: getValue("Category Bar Fore Color"),
            categoryHoverColor: getValue("Category Hover Color"),
            categoryActiveColor: getValue("Category Active Color"),
            productBgColor: getValue("Product Bg Color"),
            productNameForeColor: getValue("Product Name Fore Color"),
            productDescForeColor: getValue("Product Desc Fore Color"),
            productHoverColor: getValue("Product Hover Color"),
            productPriceBgColor: getValue("Product Price Bg Color"),
            productPriceForeColor: getValue("Product Price Fore Color"),
            productAddBtnBgColor: getValue("Product Add ( + ) Btn Bg Color"),
            footerBgColor: getValue("Footer Bg Color"),
            footerForeColor: getValue("Footer Fore Color"),
            viewCartBgColor: getValue("View Cart Bg Color"),
            viewCartForeColor: getValue("View Cart Fore Color"),
            productPopupBgColor: getValue("Product Popup Bg Color"),
            productPopupHeaderBgColor: getValue("Product Popup Header Bg Color"),
            productPopupHeaderForeColor: getValue("Product Popup Header Fore Color"),
            productPopupDescForeColor: getValue("Product Popup Desc Fore Color"),
            productPopupPriceForeColor: getValue("Product Popup Price Fore Color"),
            productPopupAddToCartForeColor: getValue("Product Popup Add To Cart Fore Color"),
            productPopupAddToCartBgColor: getValue("Product Popup Add To Cart Bg Color"),
            productPopupPlusMinusBgColor: getValue("Product Popup Plus Minus Bg Color"),
            productPopupQtyForeColor: getValue("Product Popup Qty Fore Color"),
            dealPopupOptionNameForeColor: getValue("Deal Popup Option Name Fore Color"),
            dealPopupProductNameForeColor: getValue("Deal Popup Product Name Fore Color"),
          },
        });
      });
  };









  const toggleCart = () => {
    dispatch({ type: TOGGLE_CART_DRAWER, payload: !cartDrawer });
  };

  const openPizzaModal = () => {
    let halfnHalf = productListing?.Table8.map((product) => ({
      ...product,
    }));
    halfnHalf.map();

    dispatch({
      type: TOGGLE_PRODUCT_MODAL,
      payload: true,
    });
  };
  const calculateDiscountedPrice = (productDetailId, originalPrice) => {
    const discountPercent = getDiscountPercent(productDetailId);
    return discountPercent > 0
      ? originalPrice - (originalPrice * discountPercent / 100)
      : originalPrice;
  };


  const selectProduct = (obj) => {
    const productDetails = productListing?.Table?.filter((x) => x.ProductId === obj.ProductId);

    // const discountPercent = getDiscountPercent(productDetails[0].ProductDetailId);
    // Calculate discounted price and amount if applicable
    // const discountedPriceAmount = discountPercent > 0
    //   ? (obj.ProductPrice * discountPercent / 100)
    //   : obj.ProductPrice;
    // const discountedPrice = discountPercent > 0
    //   ? obj.ProductPrice - (obj.ProductPrice * discountPercent / 100)
    //   : obj.ProductPrice;

    if (obj.IsDeal === true) {
      let deal = productDetails;
      let dealItems = productListing?.Table3?.filter(
        (z) => z.ProductDetailId === deal[0].ProductDetailId
      );
      dispatch({
        type: SET_DEAL_LIST,
        payload: { deal: deal, dealItems: dealItems },
      });
      dispatch({ type: TOGGLE_DEAL_MODAL, payload: true });
      return;
    }

    // Add pricing details to each item in productDetailList
    let productDetailList = productDetails.map(detail => {
      const sizeDiscountPercent = getDiscountPercent(detail.ProductDetailId);
      const sizeDiscountedPriceAmount = sizeDiscountPercent > 0
        ? (detail.Price * sizeDiscountPercent / 100)
        : 0;
      const sizeDiscountedPrice = sizeDiscountPercent > 0
        ? detail.Price - (detail.Price * sizeDiscountPercent / 100)
        : detail.Price;

      return {
        ...detail,
        firstPrice: detail.Price,
        Price: sizeDiscountedPrice,
        discountPrice: sizeDiscountedPriceAmount
      };
    });

    let sizeList = productListing?.Table5?.filter(
      (x) => x.ProductId === obj.ProductId
    );
    let flavourList = productListing?.Table6?.filter(
      (x) => x.ProductId === obj.ProductId
    );

    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: {
        product: obj,
        productDetailList,
        sizeList,
        flavourList,
        selectedSize: sizeList[0].SizeId,
        selectedFlavour: flavourList.filter(
          (x) => x.SizeId === sizeList[0].SizeId
        )[0].FlavourId,
        selectedCategory: productDetails[0].CategoryId,
      },
    });
    dispatch({
      type: TOGGLE_PRODUCT_MODAL,
      payload: true,
    });
  };

  // add to cart for single item

  // const addToCart = (itemToAdd) => {
  //   const existingItem = cartList.find((item) => item.ProductId === itemToAdd.ProductId);

  //   if (!existingItem) {
  //     itemToAdd.Quantity = 1;
  //     dispatch({ type: SET_PRODUCT_CART, payload: [...cartList, itemToAdd] });
  //   }    

  // };

  // Add to cart single item 
  const addToCart = (product) => {

    const productDetail = productListing?.Table?.find((x) => x.ProductId === product.ProductId);

    if (productDetail) {
      const existingItem = cartList.find((item) => item.ProductId === product.ProductId);

      const discountPercent = getDiscountPercent(productDetail.ProductDetailId); // Assuming this function gets the discount percent

      const discountedPriceAmount = discountPercent > 0
        ? (product.ProductPrice * discountPercent / 100)
        : 0;

      const discountedPrice = discountPercent > 0
        ? product.ProductPrice - (product.ProductPrice * discountPercent / 100)
        : product.ProductPrice;

      if (!existingItem) {
        const itemToAdd = {
          ...productDetail,
          firstPrice: product.ProductPrice,
          Price: discountedPrice,
          discountPrice: discountedPriceAmount,
          Quantity: 1,
        };
        console.log("item to add", itemToAdd)
        // Add this item to the cart
        dispatch({ type: SET_PRODUCT_CART, payload: [...cartList, itemToAdd] });
      }
    }
  };


  const increaseQuantity = (productId, sizeId) => {
    console.log('coming here', productId, sizeId)
    dispatch({ type: INCREASE_PRODUCT_QUANTITY, payload: { productId, sizeId } });
  };

  const decreaseQuantity = (productId, sizeId) => {
    dispatch({ type: DECREASE_PRODUCT_QUANTITY, payload: { productId, sizeId } });
  };


  const deleteItemFromCart = (productId) => {
    const confirmDelete = window.confirm('Are you sure you want to remove this item from your cart?');

    if (confirmDelete) {
      let newCartList = [];

      if (productId.IsDeal) {
      } else {
        newCartList = cartList.filter((x) => x.ProductId !== productId);
      }

      dispatch({ type: SET_PRODUCT_CART, payload: newCartList });
      setLocalCart(newCartList);
    }
  };

  const calculateTotalQuantity = () => {
    if (cartList) {
      let totalQuantity = 0;
      for (const item of cartList) {
        if (item.IsDeal) {
          if (item.Quantity && item.Quantity.length === 0) {
            totalQuantity += item[0].Quantity;
          }
        } else if (item.Quantity) {
          totalQuantity += item.Quantity;
        }
      }
      return totalQuantity;
    }
    return 0; // Return 0 if cartList is not an array or is empty
  };

  const totalQuantity = calculateTotalQuantity();
  const setLocalCart = (cart) => {
    encryptData(cart, CART_LIST);
  };


  // cartitem count
  const totalItemsCount = cartList.filter(item => item.hasOwnProperty('IsDeal')).length;


  // Category code start

  const menuBarRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  // const [categoryBarBgColor] = useState('black');
  // const [categoryActiveColor] = useState('red');
  // const [categoryHoverColor] = useState('gray');
  // const [categoryBarForeColor] = useState('white');

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200,
        behavior: 'smooth',
      });
    }
  };


  const onPress = (e, i) => {
    e.preventDefault();
    setSelectedOption(productListing.Table1[i].CategoryId);
    document.getElementById(productListing.Table1[i].CategoryId).scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = 0;
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const fromTop = window.scrollY + 100;
  //     const current = productListing?.Table1?.find(category => {
  //       const section = document.getElementById(category.CategoryId);
  //       return section.offsetTop <= fromTop && section.offsetTop + section.offsetHeight > fromTop;
  //     });
  //     setSelectedOption(current ? current.id : null);
  //   };
  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  // Category code end


  const CategoryBanner = ({ name }) => {
    return (
      <div
        style={{
          backgroundColor: '#D50707', // Adjust the background color as needed
          color: 'white ', // Text color
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 'bold',
          lineHeight: 'normal',
          textAlign: 'center',
          letterSpacing: 'normal',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '20px',
          width: '100%',
          height: 'auto',
        }}
        className="my-5 py-14 sm:py-16 text-[26px] lg:text-[46px]"
      >
        {name}
      </div>
    );
  };


  return (
    <div>
      <style></style>
      <div className="page-wrap">
        <div className="page-menu" style={{ display: "none" }}>
          <div className="clogo" style={{ border: `2px solid ${themeColor}` }}>
            {/* <img src={clogoimage} alt="" /> */}
            {/* //for dynamic logo */}
            <img src={process.env.REACT_APP_BASE_URL + logo} alt="" />
          </div>
          <ul className="contact-link">
            <li>
              <Badge
                style={{ backgroundColor: "blue !important" }}
                count={calculateTotalQuantity()}
                onClick={toggleCart}
              >
                <Button className="clink-icon" centered="true" type="primary">
                  <ShoppingOutlined />
                </Button>
              </Badge>
            </li>
            {/* <li>
              <Tooltip
                placement="right"
                title="4278 Michael Street Sugar Land, TX 77478"
                // title ={branchAddress}
              >
                <Button className="clink-icon" centered="true" type="primary">
                  <HomeOutlined />
                </Button>
              </Tooltip>
            </li>
            <li>
              <Tooltip
                placement="right"
                title="tel: 123456789"
                // title ={` tell ${branchNumber}`}
              >
                <Button className="clink-icon" centered="true" type="primary">
                  <PhoneOutlined />
                </Button>
              </Tooltip>
            </li> */}
            <li>
              <Tooltip placement="right" title="Branch & Area">
                <Button
                  className="clink-icon"
                  centered="true"
                  type="primary"
                  onClick={() => showModal1()}
                >
                  <PushpinOutlined />
                </Button>
              </Tooltip>
            </li>
          </ul>
        </div>

        {mainLoader ? (
          <SkeletonLoader />
        ) : (
          <div className="page-layout">
            {topBarText && (
              <div className={`border-box py-2 px-4 mb-2 flex justify-center`} style={{backgroundColor: topBarBgColor, color: topBarForeColor}}>
                <div>
                  <span class="mx-4">{topBarText}</span>
                </div>
              </div>
            )}
            <div class="flex relative">
              <div class="absolute z-10 sm:left-[50%]">
                <img class="w-20 h-20 object-contain rounded-full" src={logo ? process.env.REACT_APP_BASE_URL + logo : ygenLogo} alt="Logo" />
              </div>
              <div class="w-full flex justify-end sm:justify-start">
                <p class="bg-top-bar-bg-color text-top-bar-fore-color px-2.5 py-2.5 rounded-md font-bold sm:text-sm sm:mr-2.5 lg:mr-0">
                  <span class="inline-flex items-center"><EnvironmentFilled /> &nbsp;{branchDetail.AreaName || branchDetail.BranchName}</span>
                </p>
                <p class="bg-top-bar-bg-color text-top-bar-fore-color px-2.5 py-2.5 rounded-md font-bold sm:text-sm sm:ml-2.5 lg:ml-2.5">
                  <span class="inline-flex items-center"><PhoneFilled />&nbsp; +923485497976</span>
                </p>
              </div>
            </div>

            <div className="page-slider my-4">
              <Carousel autoplay className="rounded-lg shadow-lg">
                {sliderimages?.map((value, index) => (
                  <div key={index} className="relative">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${value}`}
                      alt={`Banner ${index + 1}`}
                      className="w-full h-72 object-cover rounded-lg"
                    />
                    {/* <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center">
                      <h2 className="text-white text-lg font-semibold">Banner {index + 1}</h2>
                    </div> */}
                  </div>
                ))}
              </Carousel>
            </div>



            {/* category code start */}

            <Affix offsetTop={0}>
              <div className="relative bg-[#FFF303]">
                <button
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white h-full hover:bg-gray-100 text-gray-700 rounded-full p-2"
                  onClick={scrollLeft}
                >
                  &lt;
                </button>
                <div
                  ref={scrollContainerRef}
                  className="flex overflow-x-auto space-x-4 no-scrollbar px-8 py-2"
                  style={{ scrollSnapType: 'x mandatory', backgroundColor: categoryBarBgColor }}
                >
                  <Scrollspy
                    className="flex space-x-4"
                    items={productListing?.Table1?.map(category => category.CategoryId)}
                    currentClassName={`bg-[#EE1C26] text-[#FFF303]`}
                  >
                    {productListing?.Table1?.map((category, i) => (
                      <a
                        href={`#${category.CategoryId}`}
                        key={category.CategoryId}
                        onClick={(e) => onPress(e, i)}
                        onMouseEnter={() => setHoveredCategory(category.CategoryId)}
                        onMouseLeave={() => setHoveredCategory(null)}
                        className={`px-4 py-2 whitespace-nowrap rounded ${category.CategoryId === selectedOption
                          ? `text-${categoryBarBgColor} bg-${categoryActiveColor}`
                          : `text-${categoryBarForeColor}`
                          } ${hoveredCategory === category.CategoryId ? `hover:text-[#FFF303] hover:bg-[#EE1C26]` : ''}`}
                        style={{
                          borderRadius: '5px',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '14.832px',
                          lineHeight: '22.248px',
                          // backgroundColor: categoryBarBgColor,
                          // color: categoryBarForeColor
                        }}
                      >
                        {category.CategoryName}
                      </a>
                    ))}
                  </Scrollspy>
                </div>
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white hover:bg-gray-100 text-gray-700 rounded-full p-2 h-full"
                  onClick={scrollRight}
                >
                  &gt;
                </button>
              </div>
            </Affix>
            {/* category code end */}




            {/* <div className="page-slider" style={{backgroundColor:'yellow'}}>
              <Carousel autoplay>
                {sliderimages?.map((value, index) => (
                  <div>
                    <img
                      index={index}
                      src={process.env.REACT_APP_BASE_URL + value}
                      alt=""
                    />
                  </div>
                ))}
              </Carousel>
            </div> */}
            <div
              className="page-content"
              style={{ backgroundColor: backgroundColor }}
            >


              <ScrollSpy
                onUpdateCallback={(id) => setSelectedOption(id)}
                scrollThrottle={100}
                useBoxMethod={false}
              >
                <div className="container">
                  {productListing?.Table7?.length > 1 && (
                    <>
                      <Row>
                        <Col span={24}>
                          <div className="categorySection">
                            <Title level={2} className="categoryTitle">
                              Half And Half
                            </Title>
                            <div className="categoryImage">
                              <img
                                // src={
                                //   process.env.REACT_APP_BASE_URL +
                                //   // category.CategoryImage
                                // }
                                alt=""
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 30]}>
                        <Card className="item-card" >
                          <Meta
                            avatar={
                              <Avatar
                                style={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 5,
                                }}
                              />
                            }
                            title="Pizza Half-n-Half"
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "-33px",
                            }}
                          >
                            <Tooltip title="Add to Cart">
                              <Button
                                type="primary"
                                shape="circle"
                                onClick={() => showModal2()}
                              >
                                <PlusOutlined />
                              </Button>
                            </Tooltip>
                          </div>
                        </Card>
                      </Row>
                    </>
                  )}
                </div>
                <div class="floatingcart_wrapper"
                  // style={{
                  //   position: "fixed",
                  //   bottom: 0,
                  //   left: "50%",
                  //   transform: "translateX(-50%)",
                  //   cursor: "pointer",
                  //   zIndex: 1000,
                  // }}
                  onClick={toggleCart}
                  style={{ backgroundColor: viewCartBgColor }}
                >
                  <Card
                    className="card shadow-xl"
                  // style={{
                  //   height: "80px", // Set the height to 40px
                  //   backgroundColor: "red",
                  //   color: "white",
                  //   display: "flex",
                  //   justifyContent: "center", // Center content horizontally
                  //   alignItems: "center", // Center content vertically
                  // }}
                  >
                    <div className="row">
                      <div className="col"><p className="quant_text" style={{ color: viewCartBgColor, backgroundColor: viewCartForeColor }}>{totalItemsCount}</p></div>
                      {totalItemsCount > 0 ? (
                        <>
                          <div className="col viewcart" style={{ color: viewCartForeColor, fontSize: '17px' }}>View Cart</div>
                          <div className="col amount">
                            <p className="no_margin" style={{ color: viewCartForeColor, fontSize: '17px' }}> Rs. {totalAmount.toFixed(2)}</p>
                          </div>
                        </>
                      ) : (
                        <p className="no_margin" style={{ color: viewCartForeColor, fontSize: '17px' }}>Your cart is empty</p>
                      )}
                    </div>
                  </Card>
                </div>
                {productListing?.Table1?.map((category, index) => (


                  <div
                    key={index}
                    id={category.CategoryId}
                    className="menu-section flex justify-center"
                  >
                    <div className="container px-0 mx-0">
                      <>
                        <Row>
                          <Col span={24}>
                            <div className="categorySection"  >
                              {/* <Title level={2} className="categoryTitle">
                              {category.CategoryName.toUpperCase()}
                            </Title> */}
                              <div className="categoryImage">
                                {category.CategoryImage ? (
                                  <img
                                    style={{ borderRadius: '20px', width: '100%', height: 'auto' }} // Adjust width and height as needed
                                    className="categoryImage"
                                    src={`${process.env.REACT_APP_BASE_URL}${category.CategoryImage}`}
                                    alt="Category"
                                  />
                                ) : (
                                  <CategoryBanner name={category.CategoryName} />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[16, 20]}>
                          {productListing?.Table2.filter(
                            (x) => x.CategoryId === category.CategoryId
                          )?.map((product, ind) => (
                            product.ProductDetailCount > 1 || product.IsDeal > 0 ?
                            <Col
                            key={ind}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            xxl={8}
                            >
                            
                                <Card
                                  className="item-card h-[172px] shadow-xl"
                                  onClick={() => product.ProductDetailCount > 1 || product.IsDeal > 0 &&
                                    selectProduct(product)}
                                  style={{ cursor: "pointer", backgroundColor: productBgColor, }}
                                >
                                  <Meta
                                    avatar={


                                      <div className="w-[148px] h-[148px] flex items-center justify-center overflow-hidden rounded-md bg-gray-100">
                                        <img
                                          src={
                                            product.ProductImage
                                              ? `${process.env.REACT_APP_BASE_URL}${product.ProductImage}`
                                              : sproductimage
                                          }
                                          alt="Product"
                                          className="w-full h-full object-cover"
                                        />
                                      </div>


                                    }
                                    description={
                                      <div>
                                        <h2 style={{
                                          fontSize: '17px',
                                          lineHeight: '20px',
                                          fontWeight: 'bold',
                                          color: productNameForeColor,
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          paddingTop: '10px'
                                        }}

                                        >
                                          {product.ProductName}
                                        </h2>

                                        <p className="item-text mt-2" style={{ color: productDescForeColor }}>
                                          <p className="item-text mt-2" style={{ color: productDescForeColor, lineHeight: '1.3' }}>
                                            {product.ProductDescription === 'null' || !product.ProductDescription ? '' : product.ProductDescription}
                                          </p>
                                          {/* A quick brown fox jumps over the lazy dog. */}
                                        </p>

                                        {
                                          checkDiscountPrice(
                                            productListing.Table.filter((x) => x.ProductId === product.ProductId).sort((a, b) => a - b)[0].ProductDetailId,
                                            product.ProductPrice
                                          )[1] === true ? (
                                            <div
                                              className="item-amount"
                                              style={{ color: productPriceForeColor }}
                                            >
                                              <span
                                                className="bg-[#D50707] pl-2 text-white font-bold py-2 absolute bottom-[159px] left-64 w-28 h-7"
                                                style={{
                                                  clipPath: 'polygon(100% 0%, 80% 50%, 100% 100%, 0 100%, 0 0)',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  // justifyContent: 'center',
                                                  fontSize: '14px', // Adjust the font size if needed
                                                  whiteSpace: 'nowrap', // Prevent text from wrapping
                                                }}
                                              >
                                                {getDiscountPercent(productListing.Table.filter(
                                                  (x) => x.ProductId === product.ProductId
                                                ).sort((a, b) => a - b)[0].ProductDetailId)} % OFF
                                              </span>


                                              <span
                                                className="text-xl font-bold"
                                                style={{ color: 'black' }}
                                              >
                                                {" Rs."}
                                                {
                                                  checkDiscountPrice(
                                                    productListing.Table.filter(
                                                      (x) =>
                                                        x.ProductId ===
                                                        product.ProductId
                                                    ).sort((a, b) => a - b)[0]
                                                      .ProductDetailId,
                                                    product.ProductPrice
                                                  )[0]
                                                }
                                              </span>&nbsp;&nbsp;&nbsp;
                                              <div className="item-amount-original font-bold" style={{ color: '#969696' }}>
                                                Rs. {product.ProductPrice}{"   "}
                                              </div>

                                            </div>
                                          ) : (
                                            <span className="item-amount-original font-bold" style={{ color: 'white', backgroundColor: 'black', padding: '2px', fontSize: '20px' }}>
                                              Rs. {product.ProductPrice}{"   "}
                                            </span>
                                          )}
                                        <div className="py-3"></div>
                                      </div>
                                    }
                                    className="antcarditems-cont"
                                  />
                                  <div
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "flex-end",
                                    //   marginTop: "-32px",
                                    // }}
                                    className="ant-card-qtysec absolute bottom-2 left-[310px]"
                                  >
                                    <Tooltip title="Add to Cart">
                                      <Button
                                        type="primary"
                                        style={{
                                          background: productAddBtnBgColor,
                                          borderColor: productAddBtnBgColor,
                                          color: '#EE1C26',
                                          // padding: "3px 8px",
                                        }}
                                        shape="circle"
                                        onClick={() =>
                                          product.ProductDetailCount > 1 || product.IsDeal > 0
                                            ? selectProduct(product)
                                            : addToCart(product)
                                        }
                                        icon={<PlusOutlined />}
                                      // className="plus"
                                      >
                                        { /* <b>ADD</b> */}
                                      </Button>
                                    </Tooltip>
                                  </div>
                                  {/*   ant-card-qtysec ends */}
                                </Card>
                              </Col>
                              : <>
                                <Col
                                  key={ind}
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={12}
                                  xl={8}
                                  xxl={8}
                                >
                                  <Card
                                    className="item-card h-[172px] shadow-xl"
                                    onClick={() => product.ProductDetailCount > 1 || product.IsDeal > 0 &&
                                      selectProduct(product)}
                                    style={{ cursor: "pointer", backgroundColor: productBgColor }}
                                  >
                                    <Meta
                                      avatar={

                                        <div className="w-[148px] h-[148px] flex items-center justify-center overflow-hidden rounded-md bg-gray-100">
                                          <img
                                            src={
                                              product.ProductImage
                                                ? `${process.env.REACT_APP_BASE_URL}${product.ProductImage}`
                                                : sproductimage
                                            }
                                            alt="Product"
                                            className="w-full h-full object-cover"
                                          />
                                        </div>

                                      }

                                      description={
                                        <div>
                                          <h2 style={{
                                            fontSize: '17px',
                                            lineHeight: '20px',
                                            fontWeight: 'bold',
                                            color: productNameForeColor,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            paddingTop: '10px'
                                          }}

                                          >
                                            {product.ProductName}
                                          </h2>

                                          <p className="item-text mt-2" style={{ color: productDescForeColor }}>
                                            {product.ProductDescription === 'null' || !product.ProductDescription ? '' : product.ProductDescription}

                                          </p>

                                          {
                                            checkDiscountPrice(
                                              productListing.Table.filter((x) => x.ProductId === product.ProductId).sort((a, b) => a - b)[0].ProductDetailId,
                                              product.ProductPrice
                                            )[1] === true ? (
                                              <div
                                                className="item-amount"
                                                style={{ color: productPriceForeColor }}
                                              >
                                                <span
                                                  className="bg-[#D50707] pl-2 text-white font-bold py-2 absolute bottom-[155px] left-64 w-28 h-7"
                                                  style={{
                                                    clipPath: 'polygon(100% 0%, 80% 50%, 100% 100%, 0 100%, 0 0)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    // justifyContent: 'center',
                                                    fontSize: '14px', // Adjust the font size if needed
                                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                                  }}
                                                >
                                                  {getDiscountPercent(productListing.Table.filter(
                                                    (x) => x.ProductId === product.ProductId
                                                  ).sort((a, b) => a - b)[0].ProductDetailId)} % OFF
                                                </span>

                                                <span
                                                  className="text-xl font-bold"
                                                  style={{ color: 'black' }}
                                                >
                                                  {" Rs."}
                                                  {
                                                    checkDiscountPrice(
                                                      productListing.Table.filter(
                                                        (x) =>
                                                          x.ProductId ===
                                                          product.ProductId
                                                      ).sort((a, b) => a - b)[0]
                                                        .ProductDetailId,
                                                      product.ProductPrice
                                                    )[0]
                                                  }
                                                </span>&nbsp;&nbsp;&nbsp;

                                                <div className="item-amount-original font-bold" style={{ color: '#969696' }}>
                                                  Rs. {product.ProductPrice}{"   "}
                                                </div>
                                              </div>
                                            ) : (
                                              <span className="font-bold text-xl" style={{ color: 'white', backgroundColor: 'black', padding: '2px' }}>
                                                Rs. {product.ProductPrice}{"   "}
                                              </span>
                                            )}
                                          <div className="py-3"></div>
                                        </div>
                                      }

                                    />
                                    <div
                                      // style={{
                                      //   display: "flex",
                                      //   justifyContent: "flex-end",
                                      //   marginTop: "-32px",
                                      // }}
                                      className="ant-card-qtysec mt-1"
                                    >

                                      <Tooltip title="Add to Cart">
                                        {cartList.some((item) => item.ProductId === product.ProductId) ? (
                                          <div className="quantity-controlsc flex">
                                            {cartList
                                              .filter((item) => item.ProductId === product.ProductId)
                                              .map((item) =>
                                                item.Quantity === 1 ? (
                                                  <div className="absolute bottom-2 left-[246px] flex">
                                                    <Button
                                                      type="danger"
                                                      shape="circle"
                                                      style={{
                                                        background: productAddBtnBgColor,
                                                        borderColor: productAddBtnBgColor,
                                                        color: 'black',

                                                      }}
                                                      onClick={() => deleteItemFromCart(product.ProductId)}
                                                      icon={<DeleteOutlined />}
                                                    />
                                                    <span className="qty">{item.Quantity}</span>
                                                    <Button
                                                      type="primary"
                                                      shape="circle"
                                                      style={{
                                                        background: productAddBtnBgColor,
                                                        borderColor: productAddBtnBgColor,
                                                        color: '#EE1C26',

                                                      }}
                                                      onClick={() => increaseQuantity(product.ProductId, product.SizeId)}
                                                      icon={<PlusOutlined />}
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className="absolute bottom-2 left-[246px] flex">
                                                    <Button
                                                      type="primary"
                                                      shape="circle"
                                                      style={{
                                                        background: productAddBtnBgColor,
                                                        borderColor: productAddBtnBgColor,
                                                        color: '#EE1C26',

                                                      }}
                                                      onClick={() => decreaseQuantity(product.ProductId, product.SizeId)}
                                                      icon={<MinusOutlined />}
                                                    />
                                                    <span className="item-amount-quantity qty">{item.Quantity}</span>
                                                    <Button
                                                      type="primary"
                                                      shape="circle"
                                                      style={{
                                                        background: productAddBtnBgColor,
                                                        borderColor: productAddBtnBgColor,
                                                        color: '#EE1C26',

                                                      }}
                                                      onClick={() => increaseQuantity(product.ProductId, product.SizeId)}
                                                      icon={<PlusOutlined />}
                                                    />
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        ) : (
                                          <Button
                                            type="primary"
                                            style={{
                                              background: productAddBtnBgColor,
                                              borderColor: productAddBtnBgColor,
                                              color: '#EE1C26',

                                            }}
                                            shape="circle"
                                            onClick={() =>
                                              product.ProductDetailCount > 1 || product.IsDeal > 0
                                                ? selectProduct(product)
                                                : addToCart(product)
                                            }
                                            icon={<PlusOutlined />}
                                            className="plus absolute bottom-2 left-[310px]"
                                          >
                                            {/* <b>Add to Cart</b> */}
                                          </Button>
                                        )}
                                      </Tooltip>
                                    </div>
                                    {/*   ant-card-qtysecends  */}
                                  </Card>
                                </Col>
                              </>
                          ))}
                        </Row>
                      </>
                    </div>
                  </div>
                ))}
              </ScrollSpy>
              {/* <div className="app-section">
                <Row>
                  <Col span={24}>
                    <Title level={3} className="footerTitle">
                      Ordering Apps
                    </Title>
                    <div className="app-btn">
                      <a href="#">
                        <img src={playstore} alt="" />
                      </a>
                      <a href="#">
                        <img src={appstore} alt="" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div> */}
            </div>
            <div className="page-footer" >
              <footer
                style={{
                  backgroundColor: footerBgColor,
                  color: footerForeColor,
                  padding: '1.5rem 0',
                  paddingTop: '50px',
                  paddingBottom: '50px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    maxWidth: '1200px',
                    margin: '0 auto',
                    paddingLeft: '20px'
                  }}
                >
                  {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                  {/* <img
                        src="path/to/logo.png"
                        alt="Logo"
                        style={{ height: '2rem', marginRight: '1rem' }}
                      /> */}
                  {/* <span>Copyright &copy; </span> */}
                  {/* </div> */}
                  <div>
                    <span style={{ color: footerForeColor }}>Powered by | &nbsp;</span>
                    <a
                      href="https://www.ygen.com"
                      style={{
                        color: footerForeColor,
                        textDecoration: 'none',
                        ':hover': {
                          color: footerForeColor,
                        },
                      }}
                    >
                      Ygen
                    </a>
                  </div>
                  <div>
                    <a
                      href="/privacy-policy"
                      style={{ marginRight: '1rem', textDecoration: 'none', color: footerForeColor }}
                    >
                      &nbsp; Privacy Policy
                    </a>
                    <a
                      href="/terms-of-service"
                      style={{ textDecoration: 'none', color: footerForeColor }}
                    >
                      Terms of Service
                    </a>
                  </div>
                </div>
              </footer>

            </div>
            <Tooltip placement="right" title="Branch & Area">
              <Button
                className="clink-icon pin-icon"
                centered="true"
                type="primary"
                onClick={() => showModal1()}
                style={{ backgroundColor: viewCartBgColor, background: viewCartBgColor, color: viewCartForeColor, border: 'none' }}
              >
                <PushpinOutlined />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>

      <ProductModal
        selectedProductDetail={selectedProductDetail}
        counter={counter}
        setCounter={setCounter}
        setLocalCart={setLocalCart}
      />

      <DealModal
        selectedProductDetail={selectedProductDetail}
        counter={counter}
        setCounter={setCounter}
        setLocalCart={setLocalCart}
      />

      <HalfnHalfModal
        showModal={isModal2Visible}
        setShowModal={setIsModal2Visible}
        selectedProductDetail={selectedProductDetail}
        counter={counter}
        setCounter={setCounter}
        setLocalCart={setLocalCart}
      />
      <CartDrawer setIsModalVisible={setIsModalVisible} setLocalCart={setLocalCart} toggleCart={toggleCart} />

      <Modal

        visible={isModalVisible}
        footer={null}
        closable={Object.keys(modalData).length > 0 && !mainLoader}
        onCancel={() => setIsModalVisible(false)}
        width={500}
        centered
        className="custom-modal"
      >
        <div className="modal-header">
          <Title level={3} style={{ textAlign: 'center', marginBottom: '20px', paddingTop: '10px', paddingBottom: '10px' }}>Select Your Location</Title>
        </div>
        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          activeKey={tabIndex}
          onChange={(e) => {
            setTabIndex(e.toString());
            localStorage.setItem("index", e.toString());
          }}
        >
          <TabPane tab="Delivery" key="1" style={{ color: '#EE1C26' }}>
            <Select
              placeholder="Select City"
              name="CityId"
              value={modalData.CityId}
              onChange={(e) => setModalData({ ...modalData, CityId: e })}
              style={{ width: "100%", marginBottom: "20px" }}
            >
              {deliveryPickupData.Table &&
                deliveryPickupData.Table.map((x, i) => (
                  <Option key={i} value={x.CityId}>
                    {x.CityName}
                  </Option>
                ))}
            </Select>
            <Select
              placeholder="Select Area"
              name="areaId"
              value={modalData.AreaId}
              onChange={(e) => setModalData({ ...modalData, AreaId: e })}
              style={{ width: "100%", marginBottom: "20px" }}
              disabled={modalData.CityId === ""}
            >
              {deliveryPickupData.Table1 &&
                deliveryPickupData.Table1.filter(
                  (x) => x.CityId === modalData.CityId
                ).map((x, i) => (
                  <Option key={i} value={x.AreaId}>
                    {x.AreaName}
                  </Option>
                ))}
            </Select>
          </TabPane>
          <TabPane tab="Pick-Up" key="2" style={{ color: '#EE1C26' }}>
            <Select
              placeholder="Select City"
              name="CityId"
              value={modalData.CityId}
              onChange={(e) => setModalData({ ...modalData, CityId: e })}
              style={{ width: "100%", marginBottom: "20px" }}
            >
              {deliveryPickupData.Table &&
                deliveryPickupData.Table.map((x, i) => (
                  <Option key={i} value={x.CityId}>
                    {x.CityName}
                  </Option>
                ))}
            </Select>
            <Select
              placeholder="Select Branch"
              name="BranchId"
              value={modalData.BranchId}
              onChange={(e) => setModalData({ ...modalData, BranchId: e })}
              style={{ width: "100%", marginBottom: "20px" }}
              disabled={modalData.CityId === ""}
            >
              {deliveryPickupData.Table2 &&
                deliveryPickupData.Table2.filter(
                  (x) => x.CityId === modalData.CityId
                ).map((x, i) => (
                  <Option key={i} value={x.BranchId}>
                    {x.BranchName}
                  </Option>
                ))}
            </Select>
          </TabPane>
        </Tabs>

        <Button
          // type="primary"
          size="large"
          block
          onClick={getProducts}
          loading={isLoading}
          style={{ border: '#FFF303', backgroundColor: '#FFF303', color: "#EE1C26" }}
        >
          Select
        </Button>
      </Modal>
    </div>
  );
};

export default Main;


