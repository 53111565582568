import CryptoJS from "crypto-js";
import { LOCAL_SECRET } from "../Constants/ThemeConstants";

/**
 * Encrypt your data with .env Secret Key
 * @param {any} data any type
 * @param {String} storageName name of the local Storage
 */
export const encryptData = (data, storageName) => {
  let objData = JSON.stringify(data);
  let encryptedData = CryptoJS.AES.encrypt(objData, LOCAL_SECRET).toString();
  localStorage.setItem(storageName, encryptedData);
};

/**
 * Decrypt your storage value and return
 * @param {String} storageName name of the local Storage
 * @returns JSON parsed value
 */
export const decryptData = (storageName) => {
  let string = localStorage.getItem(storageName);
  if (string === null) return;
  const decryptedString = CryptoJS.AES.decrypt(string, LOCAL_SECRET);
  let decryptedData = decryptedString.toString(CryptoJS.enc.Utf8);
  return decryptedData === "" ? decryptedData : JSON.parse(decryptedData);
};

export const decryptPayload = (payloadKey) => {
  const result = CryptoJS.AES.decrypt(payloadKey, LOCAL_SECRET);
  let decryptedPayload = JSON.parse(result.toString(CryptoJS.enc.Utf8));
  return decryptedPayload;
};

export const encryptPayload = (payload) => {
  let objData = JSON.stringify(payload);
  let encryptedPayload = CryptoJS.AES.encrypt(objData, LOCAL_SECRET).toString();
  return encryptedPayload;
};

export const decryptURL = (urlKey) => {
  
  const result = CryptoJS.AES.decrypt(urlKey, LOCAL_SECRET);
  let decryptedString = result.toString(CryptoJS.enc.Utf8);
  return decryptedString;
};

export const encryptURL = (url) => {
  let encryptedString = CryptoJS.AES.encrypt(
    url.toString(),
    LOCAL_SECRET
  ).toString();
  return encryptedString;
};
