import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { CART_LIST } from "../Constants/ThemeConstants";
import { encryptPayload } from "../Funtions/EncryptDecrypt";

const MainLayout = () => {

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default MainLayout;
