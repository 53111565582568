import {
  Button,
  Checkbox,
  Col,
  Modal,
  Typography,
  Radio,
  Row,
  Space,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import Counter from "./Counter";
import { DeleteFilled, CloseOutlined } from "@ant-design/icons";
import sproductimage from "../Assets/images/product01.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_PRODUCT_CART,
  SET_SELECTED_PRODUCT_DETAIL,
  SET_TOTAL_AMOUNT,
  TOGGLE_CART_DRAWER,
  TOGGLE_PRODUCT_MODAL,
} from "../Redux/ReduxConstants";
import { getRandomNumber } from "../Funtions/GeneralFunction";

const { Title } = Typography;
const ProductModal = (props) => {
  const {
    productModal,
    productListing,
    themeColor,
    backgroundColor,
    cartList,
    themeSecondaryColor,
    selectedProductDetail,
    productPopupBgColor,
    productPopupHeaderBgColor,
    productPopupHeaderForeColor,
    productPopupDescForeColor,
    productPopupPriceForeColor,
    productPopupAddToCartForeColor,
    productPopupAddToCartBgColor,
    productPopupPlusMinusBgColor,
    productPopupQtyForeColor,
  } = useSelector((state) => state.ProductCartReducer);
  const dispatch = useDispatch();
  const [selectedTopping, setSelectedTopping] = useState([]);

  let { counter, setCounter, setLocalCart } = props;

  useEffect(() => {
    //debugger;
    if (cartList.length > 0) {
      dispatch({
        type: SET_TOTAL_AMOUNT,
        payload: cartList.reduce((prev, next) => {
          return prev + parseInt(next.Price);
        }, 0),
      });
    }
  }, [cartList.length]);

  const checkDiscountPrice = (productDetailId, price) => {
    let discount = productListing?.Table11?.filter(
      (discountMap) => discountMap.ProductDetailId === productDetailId
    );
    if (discount?.length > 0) {
      let discountPrice = (price * discount[0].DiscountPercent) / 100;
      return parseFloat(price - discountPrice).toFixed(2);
    }
    return parseFloat(price).toFixed(2);
  };

  const addToCart = () => {
    //debugger;
    let randomNumber = getRandomNumber(1, 1000);

    let product = selectedProductDetail.productDetailList.filter(
      (x) =>
        x.SizeId === selectedProductDetail.selectedSize &&
        x.FlavourId === selectedProductDetail.selectedFlavour
    )[0];

    if (
      selectedProductDetail?.selectedHalfAndHalf !== undefined &&
      selectedProductDetail?.selectedHalfAndHalf !== null
    ) {
      let productProperty = productListing.Table8.filter(
        (x) => x.ProductDetailId === selectedProductDetail.selectedHalfAndHalf
      )[0];
      cartList.push({
        ...product,
        RandomId: randomNumber,
        IsTopping: false,
        HalfAndHalf: false,
        OrderParentId: null,
        Quantity: counter / 2,
        Price: checkDiscountPrice(
          product.ProductDetailId,
          product.ProductDetailPropertyPrice * counter
        ),
      });

      cartList.push({
        ...productProperty,
        RandomId: randomNumber,
        IsTopping: false,
        HalfAndHalf: false,
        OrderParentId: null,
        Quantity: counter / 2,
        Price: checkDiscountPrice(
          product.ProductDetailId,
          productProperty.ProductDetailPropertyPrice * counter
        ),
      });
      message.info("Product Added Successfully");
    } else {
      cartList.push({
        ...product,
        RandomId: randomNumber,
        IsTopping: false,
        HalfAndHalf: false,
        OrderParentId: null,
        Quantity: counter,
        Price: checkDiscountPrice(
          product.ProductDetailId,
          product.Price * counter
        ),
      });
      message.info("Product Added Successfully");
    }
    selectedTopping.length > 0 &&
      selectedTopping.forEach((topping) =>
        cartList.push({
          ...topping,
          RandomId: randomNumber,
          Quantity: counter,
          IsTopping: true,
          HalfAndHalf: false,
          OrderParentId: product.ProductDetailId,
          Price: topping.Price * counter,
        })
      );
    dispatch({type: SET_PRODUCT_CART, payload: [...cartList] });
    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: { ...selectedProductDetail, selectedHalfAndHalf: null },
    });
    dispatch({ type: TOGGLE_PRODUCT_MODAL, payload: false });
    setSelectedTopping([]);
    setCounter(1);
    setLocalCart(cartList);
    dispatch({ type: TOGGLE_CART_DRAWER, payload: true });
  };

  const handleToppingInCart = (e, topping, index) => {
    if (e.target.checked === true) selectedTopping.push(topping);
    if (e.target.checked === false) selectedTopping.splice(index, 1);
    setSelectedTopping([...selectedTopping]);
  };

  const closeProductModal = () => {
    dispatch({ type: SET_SELECTED_PRODUCT_DETAIL, payload: [] });
    dispatch({ type: TOGGLE_PRODUCT_MODAL, payload: false });
  };

  const handleSizeChange = (e) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: {
        ...selectedProductDetail,
        selectedSize: e.target.value,
        selectedFlavour: selectedProductDetail?.flavourList?.filter(
          (x) => x.SizeId === e.target.value
        )[0].FlavourId,
      },
    });
  };

  const handleFlavourChange = (e) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: {
        ...selectedProductDetail,
        selectedFlavour: e.target.value,
      },
    });
  };

  return (
    <Modal
      className="product-modal dealmodal-container"
      visible={productModal}
      title={null}
      footer={null}
      width={800}
      
      closeIcon={<CloseOutlined style={{ fontSize: 24, color: 'black', backgroundColor:'pink', borderRadius:'50%', padding:'5px' }} />} // 
      onCancel={closeProductModal}
      // style={{backgroundColor: productPopupBgColor}}
    >
      <div
        // style={{
        //   padding: "17px 30px",
        //   position: "absolute",
        //   width: "100%",
        //   fontSize: 24,
        //   marginTop: -20,
        //   background: themeColor,
        //   color: themeSecondaryColor,
        //   zIndex: 999,
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
        className="dealmodal-title"
        style={{backgroundColor: productPopupHeaderBgColor}}
      >
        <Title level={3} style={{ color:productPopupHeaderForeColor, margin: 0 }}>
          {selectedProductDetail?.product?.ProductName}
        </Title>
        {/* <Button
          type="text"
          onClick={closeProductModal}
          icon={<CloseOutlined style={{ fontSize: 20 }} />}
          style={{
            color: backgroundColor,
            marginRight: -10,
          }}
        /> */}
      </div>
      <div className="modalContent dealmodal-content" >
        <Row>
          <Col xs={24} md={12} className="productModalLeft dealmodal-imgcol-brdr">
            <p className="item-description" style={{color: productPopupDescForeColor}}>
              {selectedProductDetail?.product?.ProductDescription !== 'null' && selectedProductDetail?.product?.ProductDescription}
            </p>
            <div className="item-img">
              <img
                src={
                  selectedProductDetail?.product?.ProductImage
                    ? process.env.REACT_APP_BASE_URL +
                      selectedProductDetail?.product?.ProductImage
                    : sproductimage
                }
                alt=""
              />
            </div>
          </Col>
          <Col xs={24} md={12} className="productModalRight">
            <h2>Size</h2>

            <Radio.Group
              value={selectedProductDetail?.selectedSize}
              style={{ width: "100%" }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {selectedProductDetail?.sizeList?.map((size, index) => (
                  <div
                    style={{
                      padding: 10,
                      border: `2px solid ${themeColor}`,
                      borderRadius: "3px",
                      background:
                        selectedProductDetail.selectedSize === size.SizeId
                          ? themeColor
                          : backgroundColor,
                    }}
                  >
                    <Radio
                      key={index}
                      onChange={handleSizeChange}
                      value={size.SizeId}
                      style={{
                        width: "100%",
                        height: "100%",
                        // color:
                        //   selectedProductDetail.selectedSize === size.SizeId
                        //     ? backgroundColor
                        //     : themeColor
                      }}
                    >
                      <Title
                        level={5}
                        style={{
                          margin: 0,
                          color:
                            selectedProductDetail.selectedSize === size.SizeId
                              ? themeSecondaryColor
                              : themeColor,
                        }}
                      >
                        {size.SizeName}
                      </Title>
                    </Radio>
                  </div>
                ))}
              </Space>
            </Radio.Group>
            <br />
            <br />
            <h2>Variant</h2>

            <Radio.Group
              value={selectedProductDetail?.selectedFlavour}
              style={{ width: "100%" }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                {selectedProductDetail?.flavourList
                  ?.filter(
                    (x) => x.SizeId === selectedProductDetail?.selectedSize
                  )
                  ?.map((size, index) => (
                    <div
                      style={{
                        padding: 10,
                        border: `2px solid ${themeColor}`,
                        borderRadius: "3px",
                        background:
                          selectedProductDetail.selectedFlavour ===
                          size.FlavourId
                            ? themeColor
                            : backgroundColor,
                      }}
                    >
                      <Radio
                        key={index}
                        onChange={handleFlavourChange}
                        value={size.FlavourId}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Title
                          level={5}
                          style={{
                            margin: 0,
                            color:
                              selectedProductDetail.selectedFlavour ===
                              size.FlavourId
                                ? themeSecondaryColor
                                : themeColor,
                          }}
                        >
                          {size.FlavourName}
                        </Title>
                      </Radio>
                    </div>
                  ))}
              </Space>
            </Radio.Group>
            
            {
            productListing?.Table7?.filter(
             (x) => x.SizeId === selectedProductDetail?.selectedSize
            ).length > 0 && (
              <>
                <br />
                <br />
                <h2>Topping</h2>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {productListing?.Table7?.filter(
                    (x) => x.SizeId === selectedProductDetail?.selectedSize
                  )?.map((topping, index) => (
                    <div
                      style={{
                        padding: 10,
                        border: `2px solid ${themeColor}`,
                        borderRadius: "3px",
                      }}
                    >
                      <Checkbox
                        key={index}
                        checked={
                          selectedTopping.filter(
                            (x) => x.ProductDetailId === topping.ProductDetailId
                          ).length > 0
                        }
                        onChange={(e) => handleToppingInCart(e, topping, index)}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Title level={5} style={{ margin: 0 }}>
                          {topping.ProductDetailName}
                        </Title>
                      </Checkbox>
                    </div>
                  ))}
                </Space>
              </>
            )}
            <p className="item-price" style={{ color: productPopupPriceForeColor }}>
              Rs.{" "}
              <b style={{ color: productPopupPriceForeColor }}>
                {selectedProductDetail?.selectedHalfAndHalf !== null
                  ? parseFloat(
                      (checkDiscountPrice(
                        selectedProductDetail?.productDetailList?.filter(
                          (x) =>
                            x.SizeId === selectedProductDetail?.selectedSize &&
                            x.FlavourId ===
                              selectedProductDetail?.selectedFlavour
                        )[0]?.ProductDetailId,
                        selectedProductDetail?.productDetailList?.filter(
                          (x) =>
                            x.SizeId === selectedProductDetail?.selectedSize &&
                            x.FlavourId ===
                              selectedProductDetail?.selectedFlavour
                        )[0]?.Price
                      ) +
                        selectedTopping.reduce((prev, next) => {
                          return prev + next.Price;
                        }, 0)) *
                        counter
                    ).toFixed(2)
                  : parseFloat(
                      (checkDiscountPrice(
                        selectedProductDetail?.productDetailList?.filter(
                          (x) =>
                            x.SizeId === selectedProductDetail?.selectedSize &&
                            x.FlavourId ===
                              selectedProductDetail?.selectedFlavour
                        )[0]?.ProductDetailId,
                        selectedProductDetail?.productDetailList?.filter(
                          (x) =>
                            x.SizeId === selectedProductDetail?.selectedSize &&
                            x.FlavourId ===
                              selectedProductDetail?.selectedFlavour
                        )[0]?.ProductDetailPropertyPrice
                      ) +
                        selectedTopping.reduce((prev, next) => {
                          return prev + next.Price;
                        }, 0)) *
                        counter
                    ).toFixed(2)}
              </b>
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <hr />
          </Col>
        </Row>
        <div
          style={{
           marginTop:'10px',
          
          }}
          className="fixed bottom-4 sm:static flex gap-10 sm:justify-center w-full"
        >
          <Counter counter={counter} setCounter={setCounter} />
          <Button type="primary" onClick={addToCart} className="w-auto px-10 sm:w-[55%]" style={{backgroundColor:productPopupAddToCartBgColor, border:'none', background:productPopupAddToCartBgColor,color:productPopupAddToCartForeColor, fontSize:'17px', fontWeight:'bold'}}>
            Add To Cart
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
