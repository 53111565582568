import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import Checkout from "../Pages/Checkout";
import PrivacyPolicy from "../Pages/Privacy Policy";
import Main from "../Pages/Main";
import Feedback from '../Pages/Feed Back'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {/* Web Ordering Routes */}
        <Route path="/" element={<Main />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="feed-back" element={<Feedback />} />
      </Route>
        <Route path="*" element={<div>404 Not FOund</div>} />
    </Routes>
  );
};

export default AppRoutes;
