import {
  Button,
  Checkbox,
  Col,
  message,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Counter from "./Counter";
import { DeleteFilled, CloseOutlined } from "@ant-design/icons";
import sproductimage from "../Assets/images/product01.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  SET_DEAL_LIST,
  SET_HH_DEAL_LIST,
  SET_PRODUCT_CART,
  SET_SELECTED_PRODUCT_DETAIL,
  SET_TOTAL_AMOUNT,
  TOGGLE_CART_DRAWER,
  TOGGLE_DEAL_MODAL,
} from "../Redux/ReduxConstants";
import { getRandomNumber, isNullValue } from "../Funtions/GeneralFunction";
import Title from "antd/lib/typography/Title";
import DealHalfnHalfModal from "./DealHalfnHalfModal";

const DealModal = (props) => {
  const {
    dealModal,
    themeColor,
    productListing,
    cartList,
    backgroundColor,
    selectedDealDetail,
    selectedProductDetail,
    themeSecondaryColor,
    dealHnHList,
    dealPopupOptionNameForeColor,
    dealPopupProductNameForeColor,
  } = useSelector((state) => state.ProductCartReducer);
  const dispatch = useDispatch();

  const [selectedTopping, setSelectedTopping] = useState([]);
  const [isDealHnHModalVisible, setIsDealHnHModalVisible] = useState(false);
  const [quantityCounter, setQuantityCounter] = useState(1);
  const [dealList, setDealList] = useState([]);
  const [selectedDealDesc, setSelectedDealDesc] = useState(null);

  let { counter, setCounter, setLocalCart } = props;

  useEffect(() => {

    setDealList(
      productListing?.Table4?.map((x) => ({
        ...x,
        selected: false,
        Quantity: 1,
      }))
    );

    if (cartList.length > 0) {
      dispatch({
        type: SET_TOTAL_AMOUNT,
        payload: cartList.reduce((prev, next) => {
          return prev + parseInt(next.Price);
        }, 0),
      });
    }
  }, [cartList.length, productListing]);

  // const checkDealObject = (dealItemObject, DealDescObject) => {};

  const addToCart = () => {
    console.log('checking for productListing', productListing?.Table4)
    let dealMaxQuantity = selectedDealDetail?.dealItems?.reduce((p, n) => {
      return (p += n.MaxQuantity);
    }, 0);
    let dealMinQuantity = selectedDealDetail?.dealItems?.reduce((p, n) => {
      return (p += n.Quantity);
    }, 0);
    let copyOfDealList = dealList?.filter((x) => x.selected === true);
    let selectedDescQuantity = copyOfDealList?.reduce((prev, next) => {
      return (prev += next.Quantity);
    }, 0);
    if (dealHnHList.length > 0) {
      let dealHnhQuantity = dealHnHList.reduce((prev, next) => {
        return (prev += next.Quantity);
      });
      selectedDescQuantity += dealHnhQuantity;
    }
    let mainProduct = productListing?.Table?.filter(
      (x) => x.ProductDetailId === selectedDescQuantity[0]?.ProductDetailId
    );

    if (
      selectedDescQuantity < dealMinQuantity ||
      selectedDescQuantity > dealMaxQuantity
    ) {
      message.error("Please provide correct quantity for the Deal Items");
      return;
    }
    let selectedDeal = selectedDealDetail.deal[0];
    let randomNumber = getRandomNumber(1, 1000);
    copyOfDealList.unshift({
      ...selectedDeal,
      OrderMasterId: null,
      SpecialInstruction: "",
      OrderParentId: null,
      Quantity: counter,
      ProductCode: mainProduct?.ProductCode || "",
      PriceWithoutGST: parseFloat(
        parseFloat(mainProduct.Price) * parseFloat(counter)
      ).toFixed(2),
      PriceWithGST: parseFloat(
        (parseFloat(mainProduct.Price) *
          parseFloat(counter) *
          parseFloat(mainProduct.GSTPercentage)) /
        100 +
        parseFloat(mainProduct.Price) * parseFloat(counter)
      ).toFixed(2),
      totalAmount: parseFloat(mainProduct.Price * counter).toFixed(2),
      GSTId: mainProduct.GSTId,
      DiscountPercent: 0,
      DiscountAmount: 0,
    });

    copyOfDealList.forEach((element, index) => {
      cartList.push({
        ...element,
        RandomId: randomNumber,
        IsTopping: element?.IsTopping ? true : false,
        HalfAndHalf: element?.HalfAndHalf ? true : false,
        Quantity: element.Quantity,
        Price: element.Price * counter,
        OrderParentId:
          element.OrderParentId === null ? mainProduct.ProductDetailId : null,
      });
    });

    dispatch({
      type: SET_PRODUCT_CART,
      payload: [...cartList],
    });

    dispatch({ type: TOGGLE_DEAL_MODAL, payload: false });
    setSelectedTopping([]);
    setCounter(1);
    setLocalCart(cartList);
    dispatch({ type: TOGGLE_CART_DRAWER, payload: false });
    dispatch({ type: SET_HH_DEAL_LIST, payload: [] });
  };

  const handleToppingInCart = (e, topping, index) => {
    if (e.target.checked === true) selectedTopping.push(topping);
    if (e.target.checked === false) selectedTopping.splice(index, 1);
    setSelectedTopping([...selectedTopping]);
  };

  const closeProductModal = () => {
    dispatch({ type: SET_SELECTED_PRODUCT_DETAIL, payload: [] });
    dispatch({ type: TOGGLE_DEAL_MODAL, payload: false });
    dispatch({ type: SET_HH_DEAL_LIST, payload: [] });
    setQuantityCounter(1);
    setDealList(
      productListing?.Table4?.map((x) => ({
        ...x,
        selected: false,
        Quantity: 1,
      }))
    );
  };


  const handleDescChange = (e, desc, dealItem) => {
    const maxQuantity = dealItem.MaxQuantity;
    let copyOfDealList = [...dealList];
  
    // Filter dealList items based on the current DealItemId
    const targetArray = dealList.filter((dealListItem) =>
      dealListItem.DealItemId === dealItem.DealItemId
    );
  
    if (maxQuantity === 1) {
      // Update the selected state for maxQuantity = 1
      copyOfDealList = copyOfDealList.map((dealListItem) => {
        if (dealListItem.DealItemId === dealItem.DealItemId) {
          return {
            ...dealListItem,
            selected: dealListItem.DealDescId === desc.DealDescId ? e.target.checked : false,
          };
        }
        return dealListItem;
      });
    } else if (maxQuantity === 2) {
      // Update the selected state for maxQuantity = 2
      copyOfDealList = copyOfDealList.map((dealListItem) => {
        if (dealListItem.DealItemId === dealItem.DealItemId) {
          return {
            ...dealListItem,
            selected: dealListItem.DealDescId === desc.DealDescId ? e.target.checked : dealListItem.selected,
          };
        }
        return dealListItem;
      });
  
      // Check if max quantity is exceeded after updating selected state
      const selectedQuantity = copyOfDealList.filter((dealListItem) =>
        dealListItem.DealItemId === dealItem.DealItemId && dealListItem.selected
      ).length;
      
      if (selectedQuantity > maxQuantity) {
        alert(`You can only select ${maxQuantity} items.`);
        return; // Exit the function if max quantity is exceeded
      }
    }
    setDealList(copyOfDealList);
  };
  



  // const handleDescChange = (e, desc, dealItem) => {
  //   const maxQuantity = dealItem.MaxQuantity;
  //   let copyOfDealList = [...dealList]
  //   let targetArray = []
  //   dealList.map((dealListItem) => {
  //     if(dealListItem.DealItemId == dealItem.DealItemId){
  //       targetArray.push(dealListItem)
  //     }
  //   })
  //   if(maxQuantity == 1){
  //     targetArray.map((targetArrayItem) => {
  //       if(targetArrayItem.DealDescId = desc.DealDescId){
  //         console.log("this is my target thing", targetArrayItem)
  //       }
  //     })
  //   }else if (MaxQuantity == 2){

  //   }
    
  // };
  
  
  
 

  // const handleDescChange = (e, desc, dealItem) => {
  //   let dealDescIndex = dealList?.findIndex(
  //     (x) => x.DealDescId === desc.DealDescId
  //   );
  //   let copyOfDealList = [...dealList];
  //   let selectedDescFromItemQuantity = copyOfDealList
  //     ?.filter(
  //       (x) => x.DealItemId === dealItem.DealItemId && x.selected === true
  //     )
  //     ?.reduce((prev, next) => {
  //       return (prev += next.Quantity);
  //     }, 0);

  //   /* for unchecking the item */
  //   if (e.target.checked === false) {
  //     copyOfDealList[dealDescIndex].selected = false;
  //     copyOfDealList[dealDescIndex].Quantity = 1;
  //     dispatch({ type: SET_HH_DEAL_LIST, payload: [] });
  //     if (dealHnHList.length > 0) {
  //       copyOfDealList.splice(0, 1);
  //     }
  //     setDealList(copyOfDealList);
  //     return;
  //   }
  //   /* \ for checking the item */
  //   if (e.target.checked === true) {
  //     if (selectedDescFromItemQuantity < dealItem.MaxQuantity) {
  //       copyOfDealList[dealDescIndex].selected = true;

  //       setDealList(copyOfDealList);
  //     }
  //   }
  // };

  const getDealHnHQuantity = () => {
    let dealHnhQuantity = 0;
    if (dealHnHList.length > 0) {
      dealHnhQuantity = dealHnHList.reduce((prev, next) => {
        return (prev += next.Quantity);
      });
    }
    return dealHnhQuantity.Quantity;
  };

  const handleFlavourChange = (e) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: {
        ...selectedProductDetail,
        selectedFlavour: e.target.value,
      },
    });
  };

  const handleHalfAndHalfChange = (e) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_DETAIL,
      payload: {
        ...selectedProductDetail,
        selectedHalfAndHalf: e.target.value,
      },
    });
  };

  const handleDealDescRadioBtn = () => { };

  const DealDescriptionCheckbox = (dealItem) => {
    return (
      <Radio.Group value={selectedProductDetail?.selectedFlavour}>
        <Space direction="vertical">
          {dealList
            ?.filter((x) => x.DealItemId === dealItem?.DealItemId)
            ?.map((dealDesc, index) => (
              <Radio
                key={index}
                onChange={handleDealDescRadioBtn}
                value={selectedDealDetail.selectedDescriptions?.some(
                  (x) => x.DealDescId === dealDesc.DealDescId
                )}
              >
                {dealDesc.ProductDetailName}
              </Radio>
            ))}
        </Space>
      </Radio.Group>
    );
  };

  const DealDescriptionRadio = (dealItem) => {
    return (
      <Radio.Group value={selectedProductDetail?.selectedFlavour}>
        <Space direction="vertical">
          {dealList
            ?.filter((x) => x.DealItemId === dealItem?.DealItemId)
            ?.map((dealDesc, index) => (
              <Radio
                key={index}
                onChange={handleDealDescRadioBtn}
                value={selectedDealDetail.selectedDescriptions?.some(
                  (x) => x.DealDescId === dealDesc.DealDescId
                )}
              >
                {dealDesc.ProductDetailName}
              </Radio>
            ))}
        </Space>
      </Radio.Group>
    );
  };

  const showHnHDealModal = (dealDesc) => {
    setIsDealHnHModalVisible(true);
    setSelectedDealDesc(dealDesc);
  };

  // Deal Description Counter ( + ) Add
  const increment = (dealDesc, dealItem) => {
    let dealDescIndex = dealList?.findIndex(
      (x) => x.DealDescId === dealDesc.DealDescId
    );
    let copyOfDealList = [...dealList];
    let selectedDescFromItemQuantity = copyOfDealList
      ?.filter(
        (x) => x.DealItemId === dealItem.DealItemId && x.selected === true
      )
      ?.reduce((prev, next) => {
        return (prev += next.Quantity);
      }, 0);
    if (dealHnHList.length > 0) {
      let dealHnhQuantity = dealHnHList.reduce((prev, next) => {
        return (prev += next.Quantity);
      });
      selectedDescFromItemQuantity += dealHnhQuantity;
    }
    if (selectedDescFromItemQuantity < dealItem.MaxQuantity) {
      copyOfDealList[dealDescIndex].Quantity += 1;
      setDealList(copyOfDealList);
    }
  };

  // Deal Description Counter ( - ) Minus
  const decrement = (dealDesc, dealItem) => {
    let dealDescIndex = dealList?.findIndex(
      (x) => x.DealDescId === dealDesc.DealDescId
    );
    let copyOfDealList = [...dealList];
    if (copyOfDealList[dealDescIndex].Quantity > 1) {
      copyOfDealList[dealDescIndex].Quantity -= 1;
      setDealList(copyOfDealList);
    }
  };

  return (
    <Modal
      className="product-modal dealmodal-container"
      visible={dealModal}
      title={null}
      footer={null}
      width={1140}
      closeIcon={<CloseOutlined style={{ fontSize: 24, color: 'white' }} />} // 
      onCancel={closeProductModal}
    >
      <div
        // style={{
        //   padding: "17px 30px",
        //   position: "absolute",
        //   width: "100%",
        //   fontSize: 24,
        //   marginTop: -20,
        //   background: themeColor,
        //   color: themeSecondaryColor,
        //   zIndex: 999,
        //   display: "flex",
        //   justifyContent: "space-between",
        // }}
        className="dealmodal-title"
      >
        <h3 className="item-title" style={{ color: themeSecondaryColor }}>
          {selectedDealDetail?.deal[0]?.ProductDetailName?.replaceAll(
            "- N/A",
            ""
          )}
        </h3>
        {/* <Button
          type="text"
          onClick={closeProductModal}
          icon={<CloseOutlined style={{ fontSize: 20 }} />}
          style={{
            color: backgroundColor,
            marginRight: -10,
          }}
          className="close-modal-bttn"
        /> */}
      </div>
      <div className="modalContent dealmodal-content">
        <Row>
          <Col xs={24} md={10} className="productModalLeft dealmodal-imgcol-brdr">
            <p className="item-description">
              {selectedDealDetail?.deal[0]?.ProductDescription}
            </p>
            <div className="item-img">
              <img
                src={
                  selectedDealDetail?.deal[0]?.ProductImage !== null
                    ? process.env.REACT_APP_BASE_URL +
                    selectedDealDetail?.deal[0]?.ProductImage
                    : sproductimage
                }
                alt=""
              />
            </div>
          </Col>
          <Col xs={24} md={14} className="productModalRight">
            <Space direction="vertical">
              {/* selected deal details are here samad developer ....................*/}
              {selectedDealDetail?.dealItems?.map((dealItem, index) => ( 
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Title key={index} level={4} style={{color:dealPopupProductNameForeColor}}>
                      {dealItem.DealOptionName}
                    </Title>
                    <p style={{ margin: "0 10px" }}>
                      {dealItem.MaxQuantity === dealItem.Quantity
                        ? `(Select Any ${dealItem.Quantity})`
                        : `Maximum: ${dealItem.MaxQuantity} | Minimum: ${dealItem.Quantity}`}
                    </p>
                  </div>
                  <Space direction="vertical" className="dealSpace">
                    {
                      dealList
                      ?.filter(
                        (dealListItem) =>
                          dealListItem.DealItemId === dealItem?.DealItemId &&
                          isNullValue(dealListItem?.DealDescId)
                      )
                      ?.map((dealDesc, index) => (
                        <div>
                          
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Checkbox
                              checked={dealDesc.selected}
                              onChange={(e) =>
                                handleDescChange(e, dealDesc, dealItem)
                              }
                              style={{color:dealPopupOptionNameForeColor}}
                            >
                              {dealDesc.ProductDetailName}
                            </Checkbox>
                            {/* // H&H Button and Counter */}

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {dealDesc.selected === true &&
                                dealItem.ProductPropertyId != null && (
                                  <Button
                                    size="small"
                                    type="primary"
                                    // disabled={
                                    //   !selectedDealDetail.selectedDescriptions?.some(
                                    //     (x) =>
                                    //       x.DealDescId === dealDesc.DealDescId
                                    //   ) || quantityCounter > 1
                                    // }
                                    disabled={dealItem.selected === true}
                                    onClick={() => showHnHDealModal(dealDesc)}
                                  >
                                    H&H
                                  </Button>
                                )}{" "}
                              {/* Counter */}
                              {dealDesc.selected === true &&
                                dealItem.MaxQuantity > 1 && (
                                  <div className="counter"
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    //   alignItems: "center",
                                    // }}
                                  >
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      onClick={(e) =>
                                        decrement(dealDesc, dealItem)
                                      }
                                      className="btn-minus"
                                    >
                                      -
                                    </Button>
                                    <h4 className="ant-typography">
                                      <span className="counter-text">
                                        {/* {quantityCounter} */}
                                        {dealHnHList.length > 0 &&
                                          dealHnHList[0].OrderParentId ===
                                          dealDesc.ProductDetailId
                                          ? dealDesc.Quantity +
                                          getDealHnHQuantity()
                                          : dealDesc.Quantity}
                                      </span>
                                    </h4>
                                    <Button
                                      type="primary"
                                      shape="circle"
                                      onClick={(e) =>
                                        increment(dealDesc, dealItem)
                                      }
                                      className="plus-minus"
                                    >
                                      +
                                    </Button>
                                  </div>
                                )}
                            </div>
                          </div>
                          {/* //Selected Items List */}
                          {dealDesc.selected === true &&
                            dealDesc.Quantity < 1 &&
                            dealHnHList.length > 0 &&
                            dealDesc.ProductPropertyCount == "2" &&
                            dealList
                              .filter(
                                (x) =>
                                  x.selected &&
                                  x.ProductPropertyCount == "2" &&
                                  x.Quantity < 1
                              )
                              .map((items, index) => (
                                <Tag className="edit-tag" key={index}>
                                  {dealHnHList?.map(
                                    (item, index) =>
                                      item.Quantity +
                                      " x " +
                                      item.ProductDetailName
                                  )}
                                </Tag>
                              ))}
                        </div>
                      ))}
                  </Space>
                </Fragment>
              ))}
            </Space>

            <DealHalfnHalfModal
              showModal={isDealHnHModalVisible}
              setShowModal={setIsDealHnHModalVisible}
              dealList={dealList}
              setDealList={setDealList}
              selectedProductDetail={selectedProductDetail}
              counter={counter}
              setCounter={setCounter}
              setLocalCart={setLocalCart}
              selectedDealDesc={selectedDealDesc}
              setSelectedDealDesc={setSelectedDealDesc}
            />

            {productListing?.Table7?.filter(
              (x) => x.SizeId === selectedProductDetail?.selectedSize
            ).length > 0 && (
                <>
                  <br />
                  <br />
                  <h2>Topping</h2>
                  <Space direction="vertical">
                    {productListing?.Table7?.filter(
                      (x) => x.SizeId === selectedProductDetail?.selectedSize
                    )?.map((topping, index) => (
                      <Checkbox
                        key={index}
                        checked={
                          selectedTopping.filter(
                            (x) => x.ProductDetailId === topping.ProductDetailId
                          ).length > 0
                        }
                        onChange={(e) => handleToppingInCart(e, topping, index)}
                      >
                        {topping.ProductDetailName}
                      </Checkbox>
                    ))}
                  </Space>
                </>
              )}
            {productListing?.Table8?.filter(
              (x) => x.SizeId === selectedProductDetail?.selectedSize
            ).length > 0 &&
              productListing?.Table?.filter(
                (x) =>
                  x.SizeId === selectedProductDetail?.selectedSize &&
                  x.Category === selectedProductDetail?.selectedCategory
              )[0]?.ProductDetailPropertyId !== null && (
                <>
                  <br />
                  <br />
                  <h2>Half & Half</h2>
                  <Radio.Group
                    value={selectedProductDetail.selectedHalfAndHalf || null}
                  >
                    <Space direction="vertical">
                      <Radio
                        key={-1}
                        onChange={handleHalfAndHalfChange}
                        value={null}
                      >
                        None
                      </Radio>
                      {productListing?.Table8?.filter(
                        (x) => x.SizeId === selectedProductDetail?.selectedSize
                      )?.map((HH, index) => (
                        <Radio
                          key={index}
                          onChange={handleHalfAndHalfChange}
                          value={HH.ProductDetailId}
                        >
                          {HH.ProductDetailName}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </>
              )}
              <p className="item-price" style={{ color: themeColor }}>
                Rs.{" "}
                {selectedDealDetail?.deal?.length > 0 &&
                  parseFloat(selectedDealDetail?.deal[0].Price * counter).toFixed(
                    2
                  )}
              </p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <hr />
          </Col>
        </Row>
        <div
          // style={{
          //   display: "flex",
          //   justifyContent: "flex-end",
          //   alignItems: "center",
          // }}
          className="dealmodal-counternaddcartbttn"
        >
          <Counter counter={counter} setCounter={setCounter}  />
          <Button type="primary" onClick={addToCart} className="dealmodal-addtocartbttn">
            Add To Cart
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DealModal;
