import {
  Col,
  Row,
  Card,
  Button,
  Radio,
  Tooltip,
  Typography,
  Input,
  message,
  Form,
  Modal,
  Avatar,
  Divider
} from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CheckCircleOutlined, ArrowLeftOutlined, UserOutlined, EnvironmentOutlined, HomeOutlined, PhoneOutlined, DollarOutlined, ShoppingCartOutlined, InfoCircleOutlined, CreditCardOutlined, ScheduleOutlined } from '@ant-design/icons';
import ygenLogo from '../../Assets/images/ygen-withoutBack.png'
import biryaniWala from "../../Assets/images/biryaniWala.jpeg";

import cashicon from "../../Assets/images/money-icon.png";
import cardicon from "../../Assets/images/card-icon.png";
import { DeleteFilled } from "@ant-design/icons";
import { encryptData } from "../../Funtions/EncryptDecrypt";
import {
  AREA_BRANCH_ID,
  CART_LIST,
  PAYMENT_MODE_CARD,
  PAYMENT_MODE_CASH,
} from "../../Constants/ThemeConstants";
import { SET_PRODUCT_CART } from "../../Redux/ReduxConstants";
import {
  dateformatFunction,
  getOrderObject,
  timeformatFunction,
} from "../../Funtions/GeneralFunction";
import { useEffect } from "react";
import CardPaymentModal from "../../Component/CardPaymentModal";

const { Title, Text, Paragraph } = Typography;

const initialOrderObject = {
  OperationId: 2,
  OrderMasterId: null,
  CompanyId: null,
  BranchId: null,
  AreaId: null,
  CustomerId: 129,
  PhoneId: 72,
  CustomerAddressId: 106,
  RiderId: null,
  OrderStatusId: null,
  IsAdvanceOrder: false,
  SpecialInstruction: "",
  OrderDate: null,
  OrderTime: null,
  TotalAmountWithoutGST: null,
  GSTId: null,
  TotalAmountWithGST: null,
  UserIP: "1.2.2.1",
  AlternateNumber: "",
  AdvanceOrderDate: null,
  DeliveryTime: null,
  CLINumber: "",
  OrderSourceId: 194,
  OrderSourceValue: "Web",
  DiscountId: 0,
  DeliveryCharges: null,
  OrderCancelReasonId: null,
  WaiterId: null,
  ShiftDetailId: 0,
  CounterDetailId: 0,
  OrderModeId: 111,
  Cover: 0,
  PaymentTypeId: null,
  DiscountAmount: 0,
  GSTAmount: null,
  CareOfId: null,
  BillPrintCount: 0,
  PreviousOrderMasterId: null,
  Remarks: "",
  DiscountPercent: 0,
  GSTPercent: 0,
  FinishWasteRemarks: "",
  FinishWasteReasonId: null,
  UserId: 106,
  tblOrderDetail: [
    {
      OrderMasterId: null,
      ProductDetailId: null,
      PriceWithoutGST: null,
      GSTId: null,
      PriceWithGST: null,
      OrderParentId: null,
      Quantity: null,
      SpecialInstruction: "",
      DiscountPercent: 0,
      DealItemId: null,
      DealDescId: null,
      RandomId: null,
      IsTopping: false,
      HalfAndHalf: false,
    },
  ],
  TableId: null,
  tblOrderDetailAdd: [],
  tblOrderDetailLess: [],
  tblWebCustomerDetail: [
    {
      CustomerName: "",
      PhoneNumber: "",
      Remarks: "",
      LandMark: "",
      AreaId: null,
      BranchId: null,
    },
  ],
};

const Checkout = (props) => {
  const {
    cartList,
    totalAmount,
    branchDetail,
    productListing,
    orderMode,
    themeColor,
    themeSecondaryColor,
    backgroundColor,
    footerBgColor,
    footerForeColor,
    button,
    discountAmout
  } = useSelector((state) => state.ProductCartReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let tabIndex = localStorage.getItem("index");
  const [isLoading, setIsLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({ PaymentMethod: 1 });
  const [paymentMode, setPaymentMode] = useState("CASH");
  const [gstPercent, setGstPercent] = useState(null);
  const [saleableAmount, setSaleableAmount] = useState(0);
  const [cardPaymentModal, setCardPaymentModal] = useState(false);


  /////
  const [orderIdModalVisible, setOrderIdModalVisible] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [item1, setItem1] = useState('');
  const [itemTotal, setItemTotal] = useState('');

  const [gstCharges, setGstCharges] = useState(() => {
    return productListing?.Table10?.filter(
      (x) => x.PaymentMode === 'CASH'
    )[0]?.GSTPercentage;
  });
  // eslint-disable-next-line no-unused-vars
  const [orderObject, setOrderObject] = useState({
    ...initialOrderObject,
    CompanyId: process.env.REACT_APP_COMPANYID, // decryptPayload(localStorage.getItem(COMPANY_ID)).CompanyId,
  });

  const setLocalCart = (cart) => {
    encryptData(cart, CART_LIST);
  };
  const getGstCharges = (payMode) => {
    let gst = null;
    if (payMode === "CASH") {
      gst = productListing?.Table10?.filter(
        (x) => x.PaymentMode === 'CASH'
      )[0]?.GSTPercentage;
      console.log('checking paymoent mode card gst', gst)
    }
    if (payMode === "CARD") {
      gst = productListing?.Table10?.filter(
        (x) => x.PaymentMode === 'CARD'
      )[0]?.GSTPercentage;
      console.log('checking paymoent mode card gst', gst)
    }

    setGstCharges(totalAmount > 0 ? (gst * totalAmount) / 100 : 0);
    setGstPercent(gst);
  };

  const deleteItemFromCart = (index, item) => {
    let cart = [];
    if (item.IsDeal === true) {
      cart = cartList.filter((x) => x.RandomId !== item.RandomId);
    } else {
      cartList.splice(index, 1);
      cart = cartList;
    }
    dispatch({ type: SET_PRODUCT_CART, payload: cart });
    setLocalCart(cart);
  };

  const handleChange = (e) => {
    if (e.target.name === "PaymentTypeId") {
      setPaymentMode(e.target.value);
      recalculate(e.target.value);
    } else
      setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString();
    const formattedTime = now.toLocaleTimeString();
    return `${formattedDate}, ${formattedTime}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (paymentMode === "CARD") {
    //   setCardPaymentModal(true);
    // }
    setIsLoading(true);
    let data = JSON.parse(localStorage.getItem(AREA_BRANCH_ID));

    let orderArray = getOrderObject(cartList, branchDetail);
    let finalOrder = {
      ...orderObject,
      AreaId: data.AreaId,
      BranchId: branchDetail.BranchId,
      tblOrderDetail: orderArray,
      SpecialInstruction: customerInfo.DeliveryInstruction,
      DeliveryCharges: branchDetail.DeliveryCharges,
      DeliveryTime: branchDetail.DeliveryTime,
      PaymentTypeId:
        paymentMode === "CASH"
          ? productListing?.Table10?.filter(
            (x) => x.PaymentMode === 'CASH'
          )[0].PaymentModeId
          : productListing?.Table10?.filter(
            (x) => x.PaymentMode === 'CARD'
          )[0].PaymentModeId,
      OrderStatusId: productListing?.Table14[0].OrderStatusId,
      OrderModeId: orderMode,
      GSTAmount: gstCharges,
      GSTId: branchDetail.GSTId,
      GSTPercent: gstPercent,
      TotalAmountWithoutGST: parseFloat(totalAmount).toFixed(2),
      TotalAmountWithGST: parseFloat(
        parseFloat(totalAmount) + parseFloat(gstCharges)
      ).toFixed(2),
      tblWebCustomerDetail: [
        {
          CustomerName: customerInfo.CustomerName,
          PhoneNumber: customerInfo.PhoneNumber,
          Remarks: customerInfo.Remarks,
          LandMark: customerInfo.LandMark,
          OrderModeId: tabIndex == "2" ? 110 : 111,
          AreaId: data.AreaId,
          BranchId: data.BranchId,
        },
      ],
      tblOrderExtraCharges: productListing?.Table9?.map((x) => ({
        ChargesValue: x.ChargesValue,
        ExtraChargesAmount: x.IsPercent
          ? parseFloat((x.ChargesValue / 100) * totalAmount).toFixed(2)
          : parseFloat(x.ChargesValue).toFixed(2),
        ExtraChargesId: x.ExtraChargesId,
        ExtraChargesName: x.ExtraChargesName,
        IsPercent: x.IsPercent,
        Percentage: x.ChargesValue,
      })),
      OrderDate: dateformatFunction(Date.now()),
      OrderTime: timeformatFunction(Date.now()),
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}CrudWebMobileOrder`, finalOrder)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.DataSet?.Table[0]?.HasError === 0) {
          dispatch({ type: SET_PRODUCT_CART, payload: [] });
          localStorage.setItem(CART_LIST, "");

          setOrderId(res?.data?.DataSet?.Table[0]?.OrderNumber);
          setOrderIdModalVisible(true); // Show the modal

          setItem1(
            <div>
              <Title level={4}>Order Details</Title>
              {/* <Divider /> */}
              {cartList.map((item, index) => (
                <Row key={index} justify="space-between" style={{ marginBottom: '8px', alignItems: 'center' }}>
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <Text strong style={{ fontSize: '16px' }}>{item.Quantity}</Text>
                    <Text style={{ fontSize: '16px' }}> × </Text>
                    <Text style={{ fontSize: '14px' }}>{item.ProductDetailName}</Text>
                  </Col>
                </Row>
              ))}
            </div>
          );

          setItemTotal(saleableAmount);
        }
        message.success(res?.data?.DataSet?.Table[0]?.Message);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleOrderIdModalCancel = () => {
    setOrderIdModalVisible(false);
    navigate('/');
  };

  const handlePrint = () => {
    window.print();
  };

  const calculateExtraCharges = (extraChargesArr) => {
    let additionalCharges = 0;

    extraChargesArr?.map((extraCharge) => {
      if (extraCharge.IsPercent) {
        additionalCharges =
          additionalCharges + (extraCharge.ChargesValue * totalAmount) / 100;
      } else {
        additionalCharges = additionalCharges + extraCharge.ChargesValue;
      }
    });
    return parseFloat(additionalCharges).toFixed(2);
  };

  const getSaleableAmount = () => {
    let value =
      parseFloat(totalAmount) +
      parseFloat(
        branchDetail?.DeliveryCharges ? branchDetail?.DeliveryCharges : 0
      ) +
      parseFloat(gstCharges);
    parseFloat(calculateExtraCharges(productListing?.Table9));

    setSaleableAmount(parseFloat(value).toFixed(2));
  };

  const recalculate = (payMode) => {
    console.log('the payment mode in recaulcuate is ', payMode)
    getGstCharges(payMode);
    getSaleableAmount();
  };

  useEffect(() => {
    recalculate(paymentMode);
  }, [gstCharges]);

  return (
    <div>
      <div className="page-wrap">
        <div className="page-layout" style={{ marginLeft: "0px" }}>
          <form onSubmit={handleSubmit}>
            <div
              className="page-content"
              style={{
                background: themeColor,
              }}
            >
              <div
                className="container"
                style={{
                  background: themeColor,
                  color: themeSecondaryColor,
                }}
              >
                <Row className="checkoutTopbar">
                  <Col span={3}>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(-1)}
                    >
                      <img
                        style={{ borderRadius: "5px", maxWidth: '80px', maxHeight: '80px' }}
                        src={biryaniWala ? biryaniWala : ygenLogo}
                        height="90px"
                        alt=""
                      />
                    </span>
                  </Col>
                  <Col span={21}>
                    <Title
                      level={2}
                      style={{
                        fontWeight: 700,
                        textAlign: "center",
                        marginTop: "30px",
                        marginBottom: "30px",
                        textTransform: "uppercase",
                        backgroundColor: themeColor,
                        color: themeSecondaryColor,
                      }}
                    >
                      Checkout
                    </Title>
                  </Col>
                </Row>
              </div>
              {/* <Affix offsetTop={0}> */}
              <div
                style={{
                  backgroundColor: backgroundColor,
                  paddingTop: "30px",
                  paddingBottom: "30px",
                }}
              >
                <div className="container">
                  <Row gutter={[16, 30]}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                      <Card
                        className="checkoutCard"
                        style={{ marginBottom: "30px" }}
                      >
                        <Row gutter={[8, 8]} style={{ display: 'flex', alignItems: 'center', gap: '16px', padding: '8px 0' }}>
                          <Col xxl={12} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <EnvironmentOutlined style={{ fontSize: '18px', color: '#1890ff' }} />
                            <p style={{ margin: 0 }}>
                              <b>Branch Address: </b>
                              {branchDetail.AreaName || branchDetail.BranchName}
                            </p>
                          </Col>
                          <Col xxl={12} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <ShoppingCartOutlined style={{ fontSize: '18px', color: '#1890ff' }} />
                            <p style={{ margin: 0 }}>
                              <b>Order Mode: </b>
                              <strong>
                                {orderMode === 111 ? "Delivery" : "Pick-Up"}{" "}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        {/* <h3
                          style={{
                            marginTop: "30px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                          }}
                        >
                          Customer Details */}
                        {/* </h3>
                        <hr style={{ marginBottom: "20px" }} /> */}
                        <Row style={{ marginTop: '30px' }}>
                          <Col span={24}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ fontSize: '16px' }} required={[true]}>
                                Full Name{" "}
                                <span style={{ color: "red" }}>*</span>{" "}
                              </p>
                              <Input
                                placeholder="Enter full name"
                                value={customerInfo.CustomerName}
                                name="CustomerName"
                                onChange={handleChange}
                                required
                                style={{ borderRadius: '10px' }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[16]}>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ fontSize: '16px' }}>
                                Mobile Number{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <Input
                                type={"number"}
                                placeholder="Enter mobile number"
                                name="PhoneNumber"
                                onChange={handleChange}
                                value={customerInfo.PhoneNumber}
                                style={{ borderRadius: '10px' }}
                                required
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ fontSize: '16px' }}>Alternate Number</p>
                              <Input
                                type={"number"}
                                placeholder="Enter alternate number"
                                name="AlternatePhone"
                                onChange={handleChange}
                                value={customerInfo.AlternatePhone}
                                style={{ borderRadius: '10px' }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={[16]}>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ fontSize: '16px' }}>Nearest Landmark</p>
                              <Input
                                placeholder="Enter nearest landmark"
                                value={customerInfo.LandMark}
                                name="LandMark"
                                onChange={handleChange}
                                style={{ borderRadius: '10px' }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ fontSize: '16px' }}>Email Address</p>
                              <Input
                                type="email"
                                placeholder="Enter email address"
                                name="Email"
                                onChange={handleChange}
                                value={customerInfo.Email}
                                style={{ borderRadius: '10px' }}
                              // required
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ paddingBottom: '5px', fontSize: '16px' }}>
                                Delivery Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </p>
                              <textarea
                                placeholder="Enter delivery address"
                                name="Remarks"
                                onChange={handleChange}
                                value={customerInfo.Remarks}
                                required
                                rows={4} // Adjust the number of rows as needed
                                style={{ width: '100%', border: '1px solid #D6D6D6', borderRadius: '10px', padding: '5px' }} // Optional: Ensures the textarea takes the full width
                              />

                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <div style={{ marginBottom: "15px" }}>
                              <p style={{ paddingBottom: '5px', fontSize: '16px' }}>Delivery Instructions</p>
                              <textarea
                                placeholder="Enter delivery instructions"
                                name="DeliveryInstruction"
                                onChange={handleChange}
                                value={customerInfo.DeliveryInstruction}
                                rows={4} // Adjust the number of rows as needed
                                style={{ width: '100%', border: '1px solid #D6D6D6', borderRadius: '10px', padding: '5px' }} // Optional: Ensures the textarea takes the full width
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <h3
                          style={{
                            marginTop: "10px",
                            textTransform: "uppercase",
                          }}
                        >
                          {gstPercent}
                        </h3> */}
                        <h3
                          style={{
                            marginTop: "10px",
                            textTransform: "uppercase",
                            fontSize: '16px',
                            marginBottom: '10px'
                          }}
                        >
                          Choose Payment Method
                        </h3>
                        {/* <hr style={{ marginBottom: "20px" }} /> */}
                        <Row>
                          <Col span={24}>
                            <Radio.Group
                              value={paymentMode}
                              name="PaymentTypeId"
                              defaultValue={"CASH"}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}
                            >
                              <Radio.Button
                                value={"CASH"}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '10px',
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '8px',
                                  width: '150px',
                                  height: '60px',
                                  fontSize: '16px',
                                }}
                              >
                                <Tooltip placement="top" title="Cash On Delivery">
                                  <DollarOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                  Cash
                                </Tooltip>
                              </Radio.Button>
                              <Radio.Button
                                value={"CARD"}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  padding: '10px',
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '8px',
                                  width: '150px',
                                  height: '60px',
                                  fontSize: '16px',
                                }}
                              >
                                <Tooltip placement="top" title="Credit/Debit Card">
                                  <CreditCardOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                  Card
                                </Tooltip>
                              </Radio.Button>
                            </Radio.Group>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                      <Card className="checkoutCard">
                        <h3
                          style={{
                            marginTop: "10px",
                            fontWeight: "700",
                            textTransform: "uppercase",
                          }}
                        >
                          Order Summary
                        </h3>
                        <hr />
                        <div className="checkout">
                          <div
                            className="cart-lists"
                            style={{
                              height: "Calc(100vh - 414px)",
                              maxHeight: "Calc(100vh - 480px)",
                              overflowY: "auto",
                            }}
                          >
                            {cartList &&
                              cartList?.map((item, index) =>
                                (!item?.DealItemId && item?.IsDeal === true) ||
                                  item?.IsDeal === false ? (
                                  <div key={index} className="cart-items">
                                    <div className="cart-item-detail">
                                      <div className="cart-item-title">
                                        {item.Quantity} x{" "}
                                        {item.ProductDetailName}
                                      </div>
                                      <div className="cart-item-amount">
                                        Rs. {item.Price}
                                      </div>
                                      <div className="cart-item-prices"></div>
                                    </div>
                                    <div className="cart-item-action">
                                      <span
                                        className="remove"
                                        onClick={() =>
                                          deleteItemFromCart(index, item)
                                        }
                                      >
                                        <DeleteFilled />
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div key={index} className="cart-items">
                                    <div className="cart-item-title">
                                      <strong>
                                        {" -> " + item.Quantity} x{" "}
                                        {item.ProductDetailName}
                                      </strong>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                          <div className="total">
                            <Row style={{ marginBottom: "5px" }}>
                              <Col span={12}>Sub-Total</Col>
                              <Col span={12}>
                                <div style={{ textAlign: "right" }}>
                                  Rs {parseFloat(totalAmount).toFixed(2)}
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px" }}>
                              <Col span={12}>Sales Tax {gstPercent}%</Col>
                              <Col span={12} style={{}}>
                                <div style={{ textAlign: "right" }}>
                                  Rs. {gstCharges}
                                </div>
                              </Col>
                            </Row>
                            {/* <Row style={{ marginBottom: "5px" }}>
                              <Col span={12}>Additional Charges</Col>
                              <Col span={12} style={{}}>
                                <div style={{ textAlign: "right" }}>
                                  Rs.{" "}
                                  {calculateExtraCharges(
                                    productListing?.Table9
                                  )}
                                </div>
                              </Col>
                            </Row> */}
                            <Row style={{ marginBottom: "5px" }}>
                              <Col span={12}>Delivery Fee</Col>
                              <Col span={12} style={{}}>
                                <div style={{ textAlign: "right" }}>
                                  Rs{" "}
                                  {branchDetail.DeliveryCharges
                                    ? branchDetail.DeliveryCharges
                                    : 0}
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: "5px" }}>
                              <Col span={12}>discount Amount</Col>
                              <Col span={12} style={{}}>
                                <div style={{ textAlign: "right" }}>
                                  Rs{" "}
                                  {parseFloat(discountAmout).toFixed(2)}
                                </div>
                              </Col>
                            </Row>
                            <Row className="orderTotal">
                              <Col span={12}>
                                <p>Total</p>
                              </Col>
                              <Col span={12} style={{}}>
                                <div style={{ textAlign: "right" }}>
                                  <p>Rs {parseFloat((saleableAmount - discountAmout)).toFixed(2)}</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <Button
                            type="primary"
                            size="large"
                            block
                            htmlType="submit"
                            loading={isLoading}
                            style={{ backgroundColor: '#FFF303', border: 'none', color: '#EE1C26', marginTop: '20px' }}
                          >
                            Place Order
                          </Button>
                          {/* Modal to display the order ID */}
                          <Modal
                            visible={orderIdModalVisible}
                            onCancel={handleOrderIdModalCancel}
                            style={{ top: 20 }}
                            footer={[
                              <Button key="print" onClick={() => window.print()}>Print</Button>,
                              <Button key="close" type="primary" onClick={handleOrderIdModalCancel}>Close</Button>
                            ]}

                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar src={biryaniWala ? biryaniWala : ygenLogo} size={50} style={{ marginRight: '8px' }} />
                              {/* <Text strong style={{ fontSize: '24px' }}>YGen System</Text> */}
                            </div>
                            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                              <CheckCircleOutlined className="animated-check" style={{ fontSize: '48px', color: '#52c41a' }} />
                              <Title level={3} style={{ marginTop: '16px' }}>Thank You!</Title>
                              <Paragraph style={{ fontSize: '16px', fontWeight: 'bold' }}>Your order has been placed successfully</Paragraph>
                            </div>
                            <Divider />
                            <div style={{ marginBottom: '20px' }}>
                              <Title level={4}><ScheduleOutlined style={{ color: '#FFC107' }} /> Order Information</Title>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Order ID:</Text></Col>
                                <Col span={12}>{orderId}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Order Date:</Text></Col>
                                <Col span={12}>{getCurrentDateTime()}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Status:</Text></Col>
                                <Col span={12}><span style={{ backgroundColor: '#FFC107', color: 'white', marginTop: '3px', borderRadius: '5px', padding: '3px', fontWeight: 'bolder' }}>Received</span></Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Order Mode:</Text></Col>
                                <Col span={12}><span style={{ marginTop: '3px', borderRadius: '5px', padding: '3px', fontWeight: 'bolder' }}>{orderMode === 111 ? "Delivery" : "Pick-Up"}{" "}</span></Col>
                              </Row>
                              
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                              <Title level={4}><UserOutlined style={{ color: '#FFC107' }} /> Customer Information</Title>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Name:</Text></Col>
                                <Col span={12}>{customerInfo.CustomerName}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Email:</Text></Col>
                                <Col span={12}>{customerInfo.Email}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong> Mobile Number:</Text></Col>
                                <Col span={12}>{customerInfo.PhoneNumber}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong> Delivery Address:</Text></Col>
                                <Col span={12}>{customerInfo.Remarks}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong> Delivery Instruction:</Text></Col>
                                <Col span={12}>{customerInfo.DeliveryInstruction}</Col>
                              </Row>
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                              <Title level={4}><DollarOutlined style={{ color: '#FFC107' }} /> Payment Details</Title>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Payment Method:</Text></Col>
                                <Col span={12}>Cash</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Total Amount:</Text></Col>
                                <Col span={12}>Rs. {parseFloat(totalAmount).toFixed(2)}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Sales Tax {gstPercent}%</Text></Col>
                                <Col span={12}>Rs. {parseFloat(gstCharges).toFixed(2)}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Delivery Fee:</Text></Col>
                                <Col span={12}>Rs{" "}
                                  {branchDetail.DeliveryCharges
                                    ? branchDetail.DeliveryCharges
                                    : 0}</Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12}><Text strong>Grand Total:</Text></Col>
                                <Col span={12}>Rs. {saleableAmount}</Col>
                              </Row>
                            </div>
                            <div>
                              {item1}
                            </div>
                            <Divider />
                            <p style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}>
                              Your Order Will Be Delivered Within 30 Minutes
                            </p>
                            {/* <Divider /> */}
                            <p style={{ textAlign: 'center', fontStyle: 'italic', marginTop: '10px' }}>Powered by Ygen</p>
                          </Modal>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              padding: "5px 0",
                            }}
                            onClick={() => navigate(-1)}
                          >
                            <ArrowLeftOutlined /> Continue Shopping
                          </span>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* </Affix> */}
            </div>
          </form>
          <div className="page-footer">
            <footer
              style={{
                backgroundColor: footerBgColor,
                color: 'white',
                padding: '1.5rem 0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  maxWidth: '1200px',
                  margin: '0 auto',
                  paddingLeft: '20px'
                }}
              >
                {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
                {/* <img
                        src="path/to/logo.png"
                        alt="Logo"
                        style={{ height: '2rem', marginRight: '1rem' }}
                      /> */}
                {/* <span>Copyright &copy; </span> */}
                {/* </div> */}
                <div>
                  <span style={{ color: footerForeColor }}>Powered by | &nbsp;</span>
                  <a
                    href="https://www.ygen.com"
                    style={{
                      color: footerForeColor,
                      textDecoration: 'none',
                      ':hover': {
                        color: footerForeColor,
                      },
                    }}
                  >
                    Ygen
                  </a>
                </div>
                <div>
                  <a
                    href="/privacy-policy"
                    style={{ marginRight: '1rem', textDecoration: 'none', color: themeSecondaryColor }}
                  >
                    &nbsp; Privacy Policy
                  </a>
                  <a
                    href="/terms-of-service"
                    style={{ textDecoration: 'none', color: footerForeColor }}
                  >
                    Terms of Service
                  </a>
                </div>
              </div>
            </footer>
          </div>
        </div>
        {/* <CardPaymentModal
          cardPaymentModal={cardPaymentModal}
          setCardPaymentModal={setCardPaymentModal}
        ></CardPaymentModal> */}
      </div>
    </div>
  );
};

export default Checkout;
