import { Button, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import React from "react";
const { Title } = Typography;
const Counter = (props) => {
  const { counter, setCounter } = props;
  const {
    productPopupPlusMinusBgColor,
    productPopupQtyForeColor,
  } = useSelector((state) => state.ProductCartReducer);
  const increment = () => {
    setCounter((x) => x + 1);
  };
  const decrement = () => {
    if (counter > 1) {
      setCounter((x) => x - 1);
    }
  };

  return (
    <div
      // style={{
      //   display: "flex",
      //   justifyContent: "center",
      //   alignItems: "center"
      // }}
      className="counter"
    >
      <Button type="primary" shape="circle" onClick={decrement} className="btn-minus" style={{backgroundColor:productPopupPlusMinusBgColor,border:productPopupPlusMinusBgColor}}>
        <span className="plus-minus" style={{backgroundColor:productPopupPlusMinusBgColor}}>-</span>
      </Button>
      <Title level={4} style={{ margin: '0 5px' }}>
        <span className="counter-text"><b style={{color:productPopupQtyForeColor}}>{counter}</b></span>
      </Title>
      <Button type="primary" shape="circle" onClick={increment} style={{backgroundColor:productPopupPlusMinusBgColor, border:productPopupPlusMinusBgColor}}>
      <span className="plus-minus" style={{backgroundColor:productPopupPlusMinusBgColor}}>+</span>
      </Button>
    </div>
  );
};

export default Counter;
