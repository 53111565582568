import {
  SET_BRANCH_AREA_LIST,
  SET_BRANCH_DETAIL,
  SET_CART_LIST_METHOD,
  SET_DEAL_LIST,
  SET_PRODUCT_CART,
  SET_PRODUCT_LISTING,
  SET_PRODUCT_REQUEST,
  SET_SELECTED_PRODUCT_DETAIL,
  SET_TOTAL_AMOUNT,
  TOGGLE_CART_DRAWER,
  TOGGLE_DEAL_MODAL,
  TOGGLE_PRODUCT_MODAL,
  SET_GST_DETAIL,
  TOGGLE_LOADED,
  SET_THEME_COLOR,
  CHANGE_ORDER_MODE,
  SET_HH_DEAL_LIST,
  // INCREASE /
  INCREASE_PRODUCT_QUANTITY,
  DECREASE_PRODUCT_QUANTITY,
  REMOVE_ALL_ITEMS
} from "../ReduxConstants";

const initialState = {
  cartList: [],
  productListing: {},
  branchAreaList: [],
  productModal: false,
  dealModal: false,
  selectedProductDetail: {},
  selectedDealDetail: { deal: [], dealItems: [] },
  cartDrawer: false,
  totalAmount: 0,
  branchDetail: {},
  gstDetails: {},
  themeColor: "",
  themeSecondaryColor: "",
  backgroundColor: "",
  button: "",
  banner: "",
  logo: "",
  topBarText: "",
  topBarBgColor: "",
  topBarForeColor: "",
  categoryBarBgColor: "",
  categoryBarForeColor: "",
  categoryHoverColor: "",
  categoryActiveColor: "",
  productBgColor: "",
  productNameForeColor: "",
  productDescForeColor: "",
  productHoverColor: "",
  productPriceBgColor: "",
  productPriceForeColor: "",
  productAddBtnBgColor: "",
  footerBgColor: "",
  footerForeColor: "",
  viewCartBgColor: "",
  viewCartForeColor: "",
  productPopupBgColor: "",
  productPopupHeaderBgColor: "",
  productPopupHeaderForeColor: "",
  productPopupDescForeColor: "",
  productPopupPriceForeColor: "",
  productPopupAddToCartForeColor: "",
  productPopupAddToCartBgColor: "",
  productPopupPlusMinusBgColor: "",
  productPopupQtyForeColor: "",
  dealPopupOptionNameForeColor: "",
  dealPopupProductNameForeColor: "",
  loaded: false,
  orderMode: 111,
  dealHnHList: [],
  discountAmout: 0,
};

const ProductCartReducer = (state = initialState, action) => {
  let { type, payload } = action;
  switch (type) {
    case SET_PRODUCT_CART:
      return {
        ...state,
        cartList: payload,
      };
      case 'SET_DISCOUNT_SUM':
        return {
          ...state,
          discountAmout: payload,
        };
    case SET_HH_DEAL_LIST:
      return {
        ...state,
        dealHnHList: payload,
      };
    case SET_PRODUCT_LISTING:
      return {
        ...state,
        productListing: payload,
      };
    case SET_BRANCH_AREA_LIST:
      return {
        ...state,
        productListing: payload,
      };
    case TOGGLE_PRODUCT_MODAL:
      return {
        ...state,
        productModal: payload,
      };
    case TOGGLE_DEAL_MODAL:
      return {
        ...state,
        dealModal: payload,
      };
    case SET_CART_LIST_METHOD:
      return {
        ...state,
        productListing: payload,
      };
    case SET_SELECTED_PRODUCT_DETAIL:
      return {
        ...state,
        selectedProductDetail: payload,
      };
    case TOGGLE_CART_DRAWER:
      return { ...state, cartDrawer: payload };

    //Total Amount functionality
    case SET_TOTAL_AMOUNT:
      return { ...state, totalAmount: payload };

    case SET_BRANCH_DETAIL:
      return { ...state, branchDetail: payload };
    case SET_PRODUCT_REQUEST:
      return {
        ...state,
        branchDetail: payload.branchDetail,
        cartList: payload.cartList,
      };
    case SET_THEME_COLOR:
      return {
        ...state,
        themeColor: payload.themeColor,
        themeSecondaryColor: payload.themeSecondaryColor,
        backgroundColor: payload.backgroundColor,
        button: payload.button,
        banner: payload.banner,
        logo: payload.logo,
        topBarText: payload.topBarText,
        topBarBgColor: payload.topBarBgColor,
        topBarForeColor: payload.topBarForeColor,
        categoryBarBgColor: payload.categoryBarBgColor,
        categoryBarForeColor: payload.categoryBarForeColor,
        categoryActiveColor: payload.categoryActiveColor,
        categoryHoverColor: payload.categoryHoverColor,
        productBgColor: payload.productBgColor,
        productNameForeColor: payload.productNameForeColor,
        productDescForeColor: payload.productDescForeColor,
        productHoverColor: payload.productHoverColor,
        productPriceBgColor: payload.productPriceBgColor,
        productPriceForeColor: payload.productPriceForeColor,
        productAddBtnBgColor: payload.productAddBtnBgColor,
        footerBgColor: payload.footerBgColor,
        footerForeColor: payload.footerForeColor,
        viewCartBgColor: payload.viewCartBgColor,
        viewCartForeColor: payload.viewCartForeColor,
        productPopupBgColor: payload.productPopupBgColor,
        productPopupHeaderBgColor: payload.productPopupHeaderBgColor,
        productPopupHeaderForeColor: payload.productPopupHeaderForeColor,
        productPopupDescForeColor: payload.productPopupDescForeColor,
        productPopupPriceForeColor: payload.productPopupPriceForeColor,
        productPopupAddToCartForeColor: payload.productPopupAddToCartForeColor,
        productPopupAddToCartBgColor: payload.productPopupAddToCartBgColor,
        productPopupPlusMinusBgColor: payload.productPopupPlusMinusBgColor,
        productPopupQtyForeColor: payload.productPopupQtyForeColor,
        dealPopupOptionNameForeColor: payload.dealPopupOptionNameForeColor,
        dealPopupProductNameForeColor: payload.dealPopupProductNameForeColor,
      };
    case SET_DEAL_LIST:
      return { ...state, selectedDealDetail: payload };
    case SET_GST_DETAIL:
      return { ...state, gstDetails: payload };
    case TOGGLE_LOADED:
      return { ...state, loaded: !state.loaded };
    case CHANGE_ORDER_MODE:
      return { ...state, orderMode: payload };
    // REMOVE ALL ITEM FROM CART
    case REMOVE_ALL_ITEMS:
      return {
        ...state,
        cartList: []
      };
    // INCREASE ITEM FUNCTIONALITY
    case INCREASE_PRODUCT_QUANTITY:
      return {
        ...state,
        cartList: state.cartList.map((item) => {
          // Check if SizeId is defined
          if (payload.sizeId !== undefined) {
            // If SizeId is provided, match both ProductId and SizeId
            if (item.ProductId === payload.productId && item.SizeId === payload.sizeId) {
              return {
                ...item,
                Quantity: item.Quantity + 1,
              };
            }
          } else {
            // If SizeId is not provided, match only ProductId
            if (item.ProductId === payload.productId) {
              return {
                ...item,
                Quantity: item.Quantity + 1,
              };
            }
          }
          return item;
        }),
      };
    // DECREASE ITEM FUNCTIONALITY
    case DECREASE_PRODUCT_QUANTITY:
  return {
    ...state,
    cartList: state.cartList.map((item) => {
      // Check if SizeId is defined
      if (payload.sizeId !== undefined) {
        // If SizeId is provided, match both ProductId and SizeId
        if (item.ProductId === payload.productId && item.SizeId === payload.sizeId && item.Quantity > 0) {
          return {
            ...item,
            Quantity: item.Quantity - 1,
          };
        }
      } else {
        // If SizeId is not provided, match only ProductId
        if (item.ProductId === payload.productId && item.Quantity > 0) {
          return {
            ...item,
            Quantity: item.Quantity - 1,
          };
        }
      }
      return item;
    }),
  };

    default:
      return state;
  }
};

export default ProductCartReducer;
