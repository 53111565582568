export const SET_PRODUCT_CART = "SET_PRODUCT_CART";
export const SET_BRANCH_AREA_LIST = "SET_BRANCH_AREA_LIST";
export const SET_PRODUCT_LISTING = "SET_PRODUCT_LISTING";
export const TOGGLE_PRODUCT_MODAL = "TOGGLE_PRODUCT_MODAL";
export const SET_CART_LIST_METHOD = "SET_CART_LIST_METHOD";
export const SET_SELECTED_PRODUCT_DETAIL = "SET_SELECTED_PRODUCT_DETAIL";
export const TOGGLE_CART_DRAWER = "TOGGLE_CART_DRAWER";
export const SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT";
export const SET_BRANCH_DETAIL = "SET_BRANCH_DETAIL";
export const SET_PRODUCT_REQUEST = "SET_PRODUCT_REQUEST";
export const SET_DEAL_LIST = "SET_DEAL_LIST";
export const TOGGLE_DEAL_MODAL = "TOGGLE_DEAL_MODAL";
export const SET_GST_DETAIL = "SET_GST_DETAIL";
export const TOGGLE_LOADED = "TOGGLE_LOADED";
export const SET_THEME_COLOR = "SET_THEME_COLOR";
export const CHANGE_ORDER_MODE = "CHANGE_ORDER_MODE";
export const SET_HH_DEAL_LIST = "SET_HH_DEAL_LIST";
// ReduxConstants.js
export const INCREASE_PRODUCT_QUANTITY = "INCREASE_PRODUCT_QUANTITY";
export const DECREASE_PRODUCT_QUANTITY = "DECREASE_PRODUCT_QUANTITY";
export const REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS';


