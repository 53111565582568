import "./App.css";
import "./custom.css";

import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routes/AppRoutes";
import "antd/dist/antd.variable.min.css";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";

function App() {
  const { themeColor } = useSelector((state) => state.ProductCartReducer);

  ConfigProvider.config({
    theme: {
      primaryColor: themeColor, //"#df368d", // "#4561B9",
    },
  });

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
