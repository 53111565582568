import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Drawer, Row } from "antd";
import { DeleteFilled, DeleteOutlined, CloseOutlined, ArrowRightOutlined, PlusOutlined, MinusOutlined, n } from "@ant-design/icons";
import placeholderimage from "../Assets/images/placeholderimg.png";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import product01 from '../Assets/images/product01.jpg'

import {
  DECREASE_PRODUCT_QUANTITY,
  INCREASE_PRODUCT_QUANTITY,
  SET_PRODUCT_CART,
  TOGGLE_CART_DRAWER,
  SET_TOTAL_AMOUNT,
  REMOVE_ALL_ITEMS,

} from "../Redux/ReduxConstants";
import { PAYMENT_MODE_CASH } from "../Constants/ThemeConstants";
import { useNavigate } from "react-router-dom";
import dummyImage from '../../src/Assets/images/product01.jpg'

const CartDrawer = ({ setLocalCart, toggleCart }) => {
  const carouselRef = useRef();
  const {
    cartDrawer,
    cartList,
    totalAmount,
    branchDetail,
    themeColor,
    themeSecondaryColor,
    backgroundColor,
    productListing,
    button
  } = useSelector((state) => state.ProductCartReducer);
  console.log("the complete cart list", cartList)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // calCulating Tax for Drawer..........................
  const [gstCharges, setGstCharges] = useState(() => productListing?.Table10 || []);
  const [commonGSTPercentage, setCommonGSTPercentage] = useState(null);

  useEffect(() => {

    const percentage = getCommonGSTPercentage(gstCharges);
    setCommonGSTPercentage(percentage);
  }, [gstCharges]);

  function getCommonGSTPercentage(array) {
    if (array.length < 2) {
      return null; // Handle cases where array has fewer than 2 elements
    }

    const firstGST = array[0].GSTPercentage;
    const allSame = array.every(item => item.GSTPercentage === firstGST);

    return allSame ? firstGST : null;
  }

  // Delete item from cart with confirmation alert
  const deleteItemFromCart = (index, item) => {
    const confirmed = window.confirm('Are you sure you want to remove this item from your cart?');

    if (confirmed) {
      let newCartList = [];

      if (item.IsDeal) {
        newCartList = cartList.filter((x) => x.RandomId !== item.RandomId);
      } else {
        cartList.splice(index, 1);
        newCartList = cartList;
      }

      dispatch({ type: SET_PRODUCT_CART, payload: newCartList });
      setLocalCart(newCartList);
    }
  };

  const addToCart = (itemToAdd) => {
    const existingItem = cartList.find((item) => item.ProductId === itemToAdd.ProductId);

    if (!existingItem) {
      itemToAdd.Quantity = 1;
      dispatch({ type: SET_PRODUCT_CART, payload: [...cartList, itemToAdd] });
    }
  };
  const deleteAllItems = () => {
    const confirmed = window.confirm('Are you sure you want to remove all items from your cart?');

    if (confirmed) {
      dispatch({ type: SET_PRODUCT_CART, payload: [] });
      setLocalCart([]);
    }
  };



  const increaseQuantity = (productId, sizeId) => {
    dispatch({ type: INCREASE_PRODUCT_QUANTITY, payload: { productId, sizeId } });
  };

  const decreaseQuantity = (productId, sizeId) => {
    dispatch({ type: DECREASE_PRODUCT_QUANTITY, payload: { productId, sizeId } });
  };

  const calculateTotalAmount = () => {

    let total = 0;
    if (Array.isArray(cartList)) {
      for (const item of cartList) {
        console.log(item)
        total += item.firstPrice !== undefined ? item.firstPrice * item.Quantity : item.Price * item.Quantity;
      }

    }
    return total;
  };

  const sumDiscountPrices = () => {
    return cartList.reduce((total, item) => {
      return total + (item.discountPrice || 0); // Ensure discountPrice is defined
    }, 0);
  };

  useEffect(() => {
    const totalAmount = calculateTotalAmount();

    dispatch({ type: SET_TOTAL_AMOUNT, payload: totalAmount });
  }, [cartList, dispatch, totalAmount]);

  const totalAmountWithDilivery = calculateTotalAmount() + branchDetail.DeliveryCharges + ((commonGSTPercentage * totalAmount) / 100);
  const contentStyle = "m-0 h-40 flex items-center justify-center text-white text-xl bg-blue-900";
  const CustomPrevArrow = ({ onClick }) => (
    <Button
      type="text"
      shape="circle"
      icon={<LeftOutlined />}
      onClick={onClick}
      className="absolute left-[-15px] top-[80px] transform -translate-y-1/2 z-10"
    />
  );

  const CustomNextArrow = ({ onClick }) => (
    <Button
      type="text"
      shape="circle"
      icon={<RightOutlined />}
      onClick={onClick}
      className="absolute right-[-20px] top-[80px] transform -translate-y-1/2 z-10"
    />
  );
  return (
    <Drawer
      title={null}
      placement="right"
      size="large"
      width={720} // Adjust the width value as needed
      closable={false}
      visible={cartDrawer}
      className="cartdrawer-body"
      onClose={() => dispatch({ type: TOGGLE_CART_DRAWER, payload: false })}
      maskClosable={true}
    >
      <div
        style={{
          // background: themeColor,
          color: themeColor,
        }}
        className="cartdrawer_titlewrapper"
      >
        <Button
          type="text"
          onClick={() => dispatch({ type: TOGGLE_CART_DRAWER, payload: false })}
          icon={<CloseOutlined />}
          style={{
            color: themeColor,
            marginRight: 10,
            backgroundColor: 'pink',
            padding: '5px',
            borderRadius: '50%'
          }}
        // className="close_button"
        />
        <span className="cartdrawer_title" style={{ color: themeColor }}>Product Cart</span>
        <DeleteFilled style={{ fontSize: 22, marginLeft: "auto", cursor: "pointer", color: themeColor }} onClick={deleteAllItems} />
      </div>

      {cartList?.length && cartList?.length > 0 ? (
        <>

          <div /*style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}*/ className="contents-wrapper cartdrawer-wrapper">

            <div
              className="cart-lists scrollable-sec"
              style={{

                maxHeight: "Calc(100vh - 90px)",
                overflowY: "auto"
              }}
            >
              <div className="mb-2 ">
                <h2 className="text-xl font-semibold text-gray-800">Your Items</h2>
              </div>
              {console.log('checking cartlist for cart items', cartList)}
              {cartList &&
                cartList?.map((item, index) =>

                  (!item?.DealItemId && item?.IsDeal === true) ||
                    item?.IsDeal === false ? (
                    <div key={index} className="flex pt-1">
                      <div>
                        <img
                          src={item.ProductImage ? process.env.REACT_APP_BASE_URL + item.ProductImage : product01}
                          alt={item.ProductName ? item.ProductName.split(" ")[0] : ""}
                          className="object-cover rounded-lg mb-2 w-14 h-14"
                        />
                      </div>
                      <div className="pl-2 w-full">
                        <div className="" style={{ color: themeColor }}>
                          {item.ProductName ? item.ProductName.split(" ").slice(0, 3).join(" ") : ""}
                        </div>
                        <div className="cart-item-amount flex justify-between mt-2" style={{ color: 'black' }}>
                          <p>Rs. {item.Price ? item.Price : item.ProductPrice}</p>
                          <div className="mb-2 flex p-[5px] border-2 justify-between w-20 rounded-xl">
                            {item.Quantity === 1 ? (
                              <DeleteOutlined style={{ fontSize: 16, cursor: "pointer", color: themeColor }} onClick={() => deleteItemFromCart(index, item)} />
                            ) : (
                              <MinusOutlined onClick={() => decreaseQuantity(item.ProductId, item.SizeId)} style={{ fontSize: 16, cursor: "pointer", color: themeColor }} />
                            )}
                            <p>{item.Quantity}</p>
                            <PlusOutlined onClick={() => increaseQuantity(item.ProductId, item.SizeId)} style={{ fontSize: 16, cursor: "pointer", color: themeColor }} />
                          </div>
                        </div>
                        <div className="cart-item-prices"></div>
                      </div>


                    </div>
                  ) : (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '5px 0',
                        padding: '5px 10px',
                        backgroundColor: '#f9f9f9',
                        // borderLeft: '3px solid #A41D23',
                        borderRadius: '4px',
                        marginLeft: '20px', // Indicating it as a child
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          backgroundColor: themeColor,
                          color: 'white',
                          textAlign: 'center',
                          lineHeight: '20px',
                          marginRight: '8px',
                          fontSize: '12px',
                        }}
                      >
                        {item.Quantity}
                      </span>
                      <div>
                        <div style={{
                          fontSize: '13px',
                          fontWeight: '500',
                          color: themeColor,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                          {item.ProductDetailName.split(" ").slice(0, 4).join(" ")}
                        </div>
                      </div>
                    </div>

                  )
                )}

              {/*   scrollable-sec ends   */}
              {/* {similar item} */}



              <div className="py-3 px-1 bg-[#F7F7F7] rounded-lg shadow-md">
                <div className="mb-2">
                  <h2 className="text-xl font-semibold text-gray-800">Similar Items</h2>
                </div>
                <Carousel
                  arrows
                  prevArrow={<CustomPrevArrow />}
                  nextArrow={<CustomNextArrow />}
                  dots={false}
                  ref={carouselRef}
                  slidesToShow={2}
                  infinite={false}
                  className="custom-carousel"
                >

                  {productListing?.Table.filter((a) => a.IsPromotion === true).map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col  items-center p-3  rounded-lg"
                      style={{ width: 'calc(100% / 2 - 16px)' }}
                    >
                      <div className="relative w-full">
                        <img
                          src={item.ProductImage ? process.env.REACT_APP_BASE_URL + item.ProductImage : dummyImage}
                          alt={item.ProductName ? item.ProductName.split(" ")[0] : ""}
                          className="object-cover rounded-lg mb-2 w-full h-36"
                        />
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() => addToCart(item)}
                          icon={<PlusOutlined />}
                          className="absolute rounded-full bottom-2 right-2 flex items-center justify-center"
                          style={{ backgroundColor: 'white', color: 'black', border: 'none' }}
                        />
                      </div>
                      <div className=" mt-2">
                        <div className="font-semibold text-sm mb-1">
                          {item.ProductName ? item.ProductName.split(" ").slice(0, 2).join(" ") : ""}
                        </div>
                        <div className="text-gray-600 text-xs">Rs. {item.Price}</div>
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>
              {/*   Similar-items-carousel ends  */}

              <div className="checkout" >
                <div className="total">
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={12}>Sub-Total</Col>
                    <Col span={12}>
                      <div style={{ textAlign: "right" }}>
                        Rs {parseFloat(totalAmount).toFixed(2)}
                      </div>
                    </Col>
                  </Row>
                  {commonGSTPercentage !== null && (
                    <Row style={{ marginBottom: "5px" }}>
                      <Col span={12}>Sales Tax {commonGSTPercentage !== null ? commonGSTPercentage : 'N/A'}%</Col>
                      <Col span={12} style={{}}>
                        <div style={{ textAlign: "right" }}>
                          <p>Rs {totalAmount > 0 ? (commonGSTPercentage * totalAmount) / 100 : 0}</p>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="delivery-fee" style={{ marginBottom: "5px" }}>
                    <Col span={12} style={{ color: 'black' }}>Delivery Fee</Col>
                    <Col span={12} style={{}}>
                      <div style={{ textAlign: "right", color: 'black' }}>
                        Rs{" "}
                        {branchDetail.DeliveryCharges ? branchDetail.DeliveryCharges : 0}
                      </div>
                    </Col>
                  </Row>
                  {
                    sumDiscountPrices() > 0 && (
                      <Row style={{ marginBottom: "10px" }} className="delivery-fee">
                        <Col span={12} style={{ color: 'black' }}>Discount Amount</Col>
                        <Col span={12} style={{}}>
                          <div style={{ textAlign: "right", color: "black" }}>
                            Rs{" "}
                            {parseFloat(sumDiscountPrices()).toFixed(2)}
                          </div>
                        </Col>
                      </Row>
                    )
                  }
                  <Row className="orderTotal" style={{ marginBottom: '20px', borderTop: `1px solid ${themeColor}` }}>
                    <Col span={12}>
                      <p>Total</p>
                    </Col>
                    <Col span={12} style={{}}>
                      <div style={{ textAlign: "right" }}>
                        {/* <p>Rs. {calculateTotalAmount().toFixed(2)}</p> */}
                        <p>Rs. {parseFloat(totalAmountWithDilivery - sumDiscountPrices()).toFixed(2)}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <div className="delivery-info checkout-delivery-info">
                  <p className="minimum-orderinfo">
                    Minimum Order: Rs.{" "}
                    {parseInt(
                      branchDetail.MinimumOrder ? branchDetail.MinimumOrder : 0
                    )}
                    /-
                  </p>
                  <p style={{ marginBottom: 0 }} className="orderdelivery-info">
                    Your Order will be delivered approximately in<span className="time-info">{" "}
                      {branchDetail.DeliveryTime}</span> minutes
                  </p>
                </div> */}
              </div>
              {/*   checkout ends    */}

            </div>

            <Button
              type="primary"
              size="large"
              style={{ position: 'fixed', border: 'none', bottom: '10px', color: '#EE1C26', width: '365px', backgroundColor: '#FFF303', fontWeight: 'bolder', fontSize: '20px' }}
              block
              onClick={() => {
                toggleCart();
                navigate("/checkout");
                dispatch({ type: 'SET_DISCOUNT_SUM', payload: sumDiscountPrices() });
              }}
              disabled={
                parseFloat(
                  cartList.reduce((prev, next) => {
                    return prev + parseInt(next.Price);
                  }, 0)
                ).toFixed(2) <= branchDetail.MinimumOrder
              }
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span></span>
                <span style={{ fontWeight: 'bolder', fontSize: '20px' }}>Checkout</span>
                <span
                  style={{
                    borderRadius: '50%',
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#EE1C26',
                    color: '#FFF303',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArrowRightOutlined />
                </span>
              </div>
            </Button>
          </div>
        </>
      ) : (
        <div className="cart-placeholder">
          <div className="placeholder-image">
            <img src={placeholderimage} alt="" />
          </div>
          <p className="placeholder-text">
            Your cart is empty <br /> Add items to get started
          </p>
        </div>
      )}
    </Drawer>
  );
};

export default CartDrawer;
