import {
  Avatar,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import sproductimage from '../Assets/images/product01.jpg';
import { getRandomNumber } from '../Funtions/GeneralFunction';
import {
  SET_DEAL_LIST,
  SET_HH_DEAL_LIST,
  SET_PRODUCT_CART,
  TOGGLE_CART_DRAWER,
} from '../Redux/ReduxConstants';

const { Meta } = Card;
const { Option } = Select;

const DealHalfnHalfModal = (props) => {
  const { cartList, productListing } = useSelector(
    (state) => state.ProductCartReducer
  );

  const dispatch = useDispatch();
  const [sortedArray, setSortedArray] = useState([]);
  const [selectedHnHList, setSelectedHnHList] = useState([]);
  const [selectCount, setSelectCount] = useState('2');

  const checkDiscountPrice = (productDetailId, price) => {
    let discount = productListing?.Table11?.filter(
      (discountMap) => discountMap.ProductDetailId === productDetailId
    );
    if (discount?.length > 0) {
      let discountPrice = (price * discount[0].DiscountPercent) / 100;
      return parseFloat(price - discountPrice).toFixed(2);
    }
    return parseFloat(price).toFixed(2);
  };

  const handleSortChange = (value) => {
    let sorted;

    if (value === 'High-Low') {
      sorted = productListing?.Table8.sort(
        (x, y) => y.ProductDetailPropertyPricex - x.ProductDetailPropertyPrice
      );
    } else {
      sorted = productListing?.Table8.sort(
        (x, y) => x.ProductDetailPropertyPrice - y.ProductDetailPropertyPrice
      );
    }
    setSortedArray(sorted);
  };

  const deleteSelected = (item) => {
    console.log(item);
    let dealDescIndex = props.dealList?.findIndex(
      (x) => x.selected && x.ProductPropertyCount == '2'
    );
    let copyOfDealList = props.dealList;
    copyOfDealList[dealDescIndex].Quantity = 1;
    copyOfDealList.splice(0, 1);
    props.setDealList(copyOfDealList);
    setSelectedHnHList([]);
    dispatch({ type: SET_HH_DEAL_LIST, payload: [] });
  };

  const selectHalfnHalf = (obj) => {
    // let randomNumber = getRandomNumber(1, 1000);
    let selectedList = [...selectedHnHList];
    console.log('Props', props.selectedDealDesc);
    selectedList.push({
      ...obj,
      IsTopping: false,
      HalfAndHalf: true,
      OrderParentId: props.selectedDealDesc.DealDescId,
      IsDeal: true,
      DealItemId: props.selectedDealDesc.DealItemId,
      selected: true,
      Quantity: 1 / selectCount,
    });

    setSelectedHnHList(selectedList);
  };

  const addToCart = () => {
    let dealDescIndex = props.dealList?.findIndex(
      (x) => x.selected && x.ProductPropertyCount == '2'
    );
    let selectedProducts = props.dealList.filter(
      (x) => x.selected && x.ProductPropertyCount == '2'
    );
    // let copyOfHnHList = selectedHnHList.map((item, index) => ({
    //   ...item,
    //   OrderParentId: selectedProducts[0].ProductDetailId,
    //   HalfAndHalf: true,
    //   IsDeal: true,
    //   DealItemId: 24567,
    //   selected: true,
    // }));

    let copyOfDealList = props.dealList;
    copyOfDealList[dealDescIndex].Quantity = 1 / selectCount;
    copyOfDealList.splice(0, 0, ...selectedHnHList);
    props.setDealList(copyOfDealList);

    dispatch({ type: SET_HH_DEAL_LIST, payload: [...selectedHnHList] });
    setSelectedHnHList([]);
    props.setSelectedDealDesc(null);
    props.setShowModal(false);
  };

  return (
    <Modal
      title="Select Your Half-n-Half"
      visible={props.showModal}
      footer={null}
      onCancel={() => props.setShowModal(false)}
      width={1050}
      centered
    >
      <Row gutter={20}>
        <Col span={8}>
          <Select
            placeholder="Sort"
            name="sort"
            onChange={handleSortChange}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            <Option value="Low-High">Price: Low-High</Option>
            <Option value="High-Low">Price: High-Low</Option>
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Select 2 or 4"
            label="Select any 2 or 4"
            name="sort"
            value={selectCount}
            onChange={(e) => {
              setSelectCount(e);
              setSelectedHnHList([]);
            }}
            style={{ width: '100%', marginBottom: '20px' }}
          >
            <Option value="2">Select 2</Option>
            <Option value="4">Select 4</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        {selectedHnHList.map((item, index) => (
          <>
            {/* <div>
              <b>Selection{index + 1}: </b> {item.ProductDetailName}
            </div> */}
            <Tag
              className="edit-tag"
              key={index}
              closable
              onClose={(item) => deleteSelected(item)}
            >
              {item.Quantity + ' x ' + item.ProductDetailName}
            </Tag>
          </>
        ))}
        {/* <div>
          <Button
            hidden={selectedHnHList.length <= 0}
            className="remove"
            onClick={() => deleteSelected()}
          >
            <DeleteFilled />
          </Button>
        </div> */}
      </Row>
      <Row gutter={[16, 30]}>
        {productListing.Table8 &&
          productListing.Table8?.filter(
            (x) =>
              x.ProductDetailPropertyCount === selectCount &&
              !selectedHnHList.some(
                (y) => y.ProductDetailId === x.ProductDetailId
              ) &&
              (selectedHnHList.length === 0 ||
                x.SizeId === selectedHnHList[0]?.SizeId)
          )?.map((product, ind) => (
            <Col key={ind} lg={12} xxl={8}>
              <Card className="item-card" type="checkbox">
                <Meta
                  avatar={
                    <Avatar
                      src={
                        product.ProductImage === null
                          ? sproductimage
                          : process.env.REACT_APP_BASE_URL +
                            product.ProductImage
                      }
                      style={{
                        width: 120,
                        height: 120,
                        borderRadius: 5,
                      }}
                    />
                  }
                  title={product.ProductDetailName}
                  description={
                    <div>
                      <span className="item-amount">
                        Rs. {product.ProductDetailPropertyPrice}
                      </span>
                    </div>
                  }
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '-33px',
                  }}
                >
                  <Tooltip title="Add to Cart">
                    <Button
                      disabled={selectedHnHList.length === selectCount - 1}
                      type="primary"
                      shape="circle"
                      onClick={() => selectHalfnHalf(product)}
                    >
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
      <Button
        type="primary"
        disabled={selectedHnHList.length != selectCount - 1}
        onClick={addToCart}
      >
        Add to Item
      </Button>
    </Modal>
  );
};

export default DealHalfnHalfModal;
