import {
  Col,
  Row,
  Card,
  Button,
  Radio,
  Tooltip,
  Typography,
  Input,
  message,
} from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import clogoimage from "../../Assets/images/logo.jpg";

import cashicon from "../../Assets/images/money-icon.png";
import cardicon from "../../Assets/images/card-icon.png";
import { DeleteOutlined } from "@ant-design/icons";
import { encryptData } from "../../Funtions/EncryptDecrypt";
import {
  AREA_BRANCH_ID,
  CART_LIST,
  PAYMENT_MODE_CARD,
  PAYMENT_MODE_CASH,
} from "../../Constants/ThemeConstants";
import { SET_PRODUCT_CART } from "../../Redux/ReduxConstants";
import {
  dateformatFunction,
  getOrderObject,
  timeformatFunction,
} from "../../Funtions/GeneralFunction";
import { useEffect } from "react";

const { Title } = Typography;

const initialOrderObject = {
  OperationId: 2,
  OrderMasterId: null,
  CompanyId: null,
  BranchId: null,
  AreaId: null,
  CustomerId: 129,
  PhoneId: 72,
  CustomerAddressId: 106,
  RiderId: null,
  OrderStatusId: null,
  IsAdvanceOrder: false,
  SpecialInstruction: "",
  OrderDate: null,
  OrderTime: null,
  TotalAmountWithoutGST: null,
  GSTId: null,
  TotalAmountWithGST: null,
  UserIP: "1.2.2.1",
  AlternateNumber: "",
  AdvanceOrderDate: null,
  DeliveryTime: null,
  CLINumber: "",
  OrderSourceId: 194,
  OrderSourceValue: "Web",
  DiscountId: 0,
  DeliveryCharges: null,
  OrderCancelReasonId: null,
  WaiterId: null,
  ShiftDetailId: 0,
  CounterDetailId: 0,
  OrderModeId: 111,
  Cover: 0,
  PaymentTypeId: null,
  DiscountAmount: 0,
  GSTAmount: null,
  CareOfId: null,
  BillPrintCount: 0,
  PreviousOrderMasterId: null,
  Remarks: "",
  DiscountPercent: 0,
  GSTPercent: 0,
  FinishWasteRemarks: "",
  FinishWasteReasonId: null,
  UserId: 106,
  tblOrderDetail: [
    {
      OrderMasterId: null,
      ProductDetailId: null,
      PriceWithoutGST: null,
      GSTId: null,
      PriceWithGST: null,
      OrderParentId: null,
      Quantity: null,
      SpecialInstruction: "",
      DiscountPercent: 0,
      DealItemId: null,
      DealDescId: null,
      RandomId: null,
      IsTopping: false,
      HalfAndHalf: false,
    },
  ],
  TableId: null,
  tblOrderDetailAdd: [],
  tblOrderDetailLess: [],
  tblWebCustomerDetail: [
    {
      CustomerName: "",
      PhoneNumber: "",
      Remarks: "",
      LandMark: "",
      AreaId: null,
      BranchId: null,
    },
  ],
};

const Checkout = (props) => {
  const {
    themeColor,
    themeSecondaryColor,
    cartList,
    totalAmount,
    branchDetail,
    productListing,
    orderMode,
    backgroundColor,
  } = useSelector((state) => state.ProductCartReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <div className="page-wrap">
        <div className="page-layout" style={{ marginLeft: "0px" }}>
          <div
            className="page-content"
            style={{
              background: themeColor,
            }}
          >
            <div
              className="container"
              style={{
                background: themeColor,
                color: themeSecondaryColor,
              }}
            >
              <Row>
                <Col span={3}>
                  <img
                    src={clogoimage}
                    style={{ margin: 5 }}
                    height="90px"
                    alt=""
                  />
                </Col>
                <Col span={21}>
                  <Title
                    level={2}
                    style={{
                      fontWeight: 700,
                      textAlign: "center",
                      marginTop: "30px",
                      marginBottom: "30px",
                      textTransform: "uppercase",
                      color: themeSecondaryColor,
                    }}
                  >
                    Privacy Policy
                  </Title>
                </Col>
              </Row>
            </div>
            {/* <Affix offsetTop={0}> */}
            <div
              style={{
                background: backgroundColor,
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <div className="container">
                <Row gutter={[16, 30]}>
                  <Col span={24}>
                    <Card className="privacyCard">
                      <h2>Our Privacy & Security Policies</h2>
                      <p>
                        Safeguarding your privacy and the security of your
                        personal information is important to us. Please take a
                        few minutes to read the following policies so that you
                        understand how we treat your personal information. As we
                        continuously improve and expand our services, these
                        policies might change. So please check them out
                        periodically. If you have questions about our policy,
                        please click on the Feedback option to contact us via
                        our electronic feedback form.
                      </p>
                      <h2>
                        How we collect information about you. We collect
                        information in three ways
                      </h2>
                      <p>
                        <ol>
                          <li>
                            We collect information that our customers supply to
                            us.
                          </li>
                          <li>
                            We collect information from emails and other
                            contacts sent to us by our customers and users of
                            our website.
                          </li>
                          <li>
                            We collect information electronically on all users
                            of our website, including customers, by means of
                            cookies.
                          </li>
                        </ol>
                      </p>
                      <h2>
                        What personal information do we collect from our users?
                      </h2>
                      <p>
                        When you please order to use our online ordering
                        service, you are required to provide us with your full
                        legal name, email address, full street address including
                        city, and telephone number.
                      </p>
                      <h2>Security Policy</h2>
                      <p>
                        Your payment and personal information is always safe.
                        Our Secure Sockets Layer (SSL) software is the industry
                        standard and among the best software available today for
                        secure commerce transactions. It encrypts all of your
                        personal information, including credit card number,
                        name, and address, so that it cannot be read over the
                        internet.
                      </p>
                      <h2>User Data Deletion</h2>
                      <p>
                        User can request the data deletion in Mobile Application
                        from the Settings Section.
                      </p>
                      <h2>Children's Privacy</h2>
                      <p>
                        Online ordering, customer registration for online
                        ordering, and customer feedback pages of this website
                        are directed toward and designed for use by persons aged
                        13 or older. We do not establish or maintain
                        registrations for any child whom we know to be under the
                        age of 13. We do not solicit or knowingly collect
                        personally identifiable information from children under
                        the age of 13. If we discover that we have received
                        personally identifiable information from an individual
                        who indicates that he or she is, or whom we otherwise
                        have reason to believe is, under the age of 13, we will
                        delete such information from our systems.
                      </p>
                      <h2>
                        We collect additional information about how you use our
                        website and service.
                      </h2>
                      <p>
                        If you contact us by letter, phone, or email through the
                        contact information on the Feedback page, we will
                        collect your sender information and may collect the
                        other information you voluntarily disclose to us.
                        <br></br>
                        We may collect user location data with the user's
                        consent to share their location with the Indolj website
                        or mobile apps to provide the user with a quick and
                        better order delivery experience and suggest nearby
                        restaurants.<br></br>
                        In the case of the rider's app, the rider mobile app may
                        collect the rider's location information, while the
                        Indolj Rider app is running on the mobile, or in the
                        background to provide real-time order location to
                        customers.
                      </p>
                      <h2>Our use of cookies</h2>
                      <p>
                        Like many websites on the Internet, our website uses
                        cookies. Cookies are small strings of data placed on
                        users' personal computer hard drives during the exchange
                        of data that happens when your browser points to our
                        website. Cookies enable a website to identify a user's
                        browser as a previous visitor by means of a unique
                        string of numbers assigned on a previous visit. Our
                        cookies assist us in collecting information on what
                        pages of our website you access or visit, and in
                        recording any preferences you indicate on our website.
                        We also use cookies to make your experience on our
                        website more personalized, and convenient.<br></br>
                        Our cookies cannot and do not retrieve any other data
                        from your hard drive or pass on computer viruses. If you
                        are just browsing our website, a cookie identifies your
                        browser and user id (and not your identity). If you
                        place orders with us, we use cookies to assist in
                        storing your preferences and recording other session
                        information (a "session" is a single visit by you to our
                        website).
                      </p>
                      <h2>How do we store the information we collect?</h2>
                      <p>
                        We store the information you provide to us and the
                        information we collect electronically in our computer
                        databases. We own, maintain and operate our computer
                        servers containing our databases. Access to our computer
                        servers is controlled by firewalls and security
                        gatekeepers.
                      </p>
                      <h2>How do we use the information we collect?</h2>
                      <p>
                        We use the personal information about you stored in our
                        member database in various ways. First, the next time
                        you use our online ordering service and reach checkout
                        page, we will call up your information from our database
                        to make processing your order faster and easier.
                      </p>
                      <h2>Shipping Policy</h2>
                      <p>Shipped upon order.</p>
                      <h2>Our policies may change</h2>
                      <p>
                        These are our current privacy and security policies, but
                        we reserve the right to change our website and these
                        policies at any time. You should check these policies
                        periodically. If our policies regarding collection,
                        storage and use of your information change, the
                        information collected under prior policies will continue
                        to be governed by those policies, unless we obtain your
                        consent to use that information in new ways.
                      </p>
                      <h2>You can access your information</h2>
                      <p>
                        Upon your request, we will provide you with access to
                        your unique account-related information and information
                        from contacts that we maintain about you. In your
                        request, please provide us with your current specific
                        contact information so that we can accurately check our
                        records.
                      </p>
                      <h2>Please contact us with any questions or comments</h2>
                      <p>
                        We want you to enjoy your use of our service. If you
                        have any questions, or believe that a violation of this
                        policy may have occurred, please let us know. Our
                        complete contact information is provided on the header
                        or at home page.
                      </p>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
            {/* </Affix> */}
          </div>
          <div className="page-footer">
            <Row>
              <Col span={24}>
                <div className="copyright">
                  <p>
                    Powered By <a className="f-t" target="_blank" href=""></a>{"YGen"}
                    {/* |{" "}
                    <a className="" target="_blank" href="/privacy-policy">
                      Privacy Policy
                    </a> */}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
