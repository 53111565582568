export const BACKGROUND_COLOR = "red";

// LOCAL STORAGE NAMES
export const AREA_BRANCH_ID = "2de84d82-fc04-49a2-ae92-a6a21329ea5d";
export const CART_LIST = "6467c3f4-9da9-428a-a1c6-c8414331dc12";
export const COMPANY_ID = "477f3702-5bc6-4f77-882b-e82455991fbf";
export const BRANCH_DETAIL = "2ee9d06b-4fd1-471d-8b17-925888e8280c";
export const LOCAL_SECRET = "process.env.REACT_APP_LOCAL_STORAGE_SECRET";

// ENUM
export const PAYMENT_MODE_CASH = 406;
export const PAYMENT_MODE_CARD = 407;
