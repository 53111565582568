export const getRandomNumber = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const getOrderObject = (CartItemList, branch) => {
  let orderItems = CartItemList.map((x) => ({
    OrderMasterId: null,
    ProductDetailId: x.ProductDetailId || null,
    PriceWithoutGST: parseFloat(x.Price).toFixed(2) || parseFloat(0).toFixed(2),
    GSTId: branch.GSTId || null,
    PriceWithGST:
      parseFloat(
        x.Price + parseFloat((x.Price * branch.GSTPercentage) / 100).toFixed(2)
      ).toFixed(2) || null,
    OrderParentId: x?.OrderParentId || null,
    Quantity: x.Quantity || null,
    SpecialInstruction: "" || null,
    DiscountPercent: 0 || 0,
    DealItemId: x?.DealItemId || null,
    DealDescId: x.DealDescId || null,
    RandomId: x.RandomId || null,
    IsTopping: x.IsTopping || false,
    HalfAndHalf: x.HalfAndHalf || false,
    ProductPropertyId: x.ProductPropertyId || null,
  }));

  return orderItems;
};

export const dateformatFunction = (date, para = '-') => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join(para);
};

export const timeformatFunction = (date, para = ':') => {
  var d = new Date(date),
    month = '' + (d.getMinutes() + 1),
    day = '' + d.getSeconds(),
    year = d.getHours();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join(para);
};

export const isNullValue = (value) => {
  if (['', null, undefined].includes(value)) return false;
  return true;
};
